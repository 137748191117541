export class CompanyCooperateDegreeDetail {
  private _company_cooperate_degree_id!:string;
  private _qingju_score!:number;
  private _meituan_score!:number;
  private _haluo_score!:number;
  private _region_code!:string;
  private _street_code!:string;
  private _street_name!:string;
  private _time_point!:number;
  private _submitter_name!:string;

  get company_cooperate_degree_id(): string {
    return this._company_cooperate_degree_id;
  }

  set company_cooperate_degree_id(value: string) {
    this._company_cooperate_degree_id = value;
  }

  get qingju_score(): number {
    return this._qingju_score;
  }

  set qingju_score(value: number) {
    this._qingju_score = value;
  }

  get meituan_score(): number {
    return this._meituan_score;
  }

  set meituan_score(value: number) {
    this._meituan_score = value;
  }

  get haluo_score(): number {
    return this._haluo_score;
  }

  set haluo_score(value: number) {
    this._haluo_score = value;
  }

  get region_code(): string {
    return this._region_code;
  }

  set region_code(value: string) {
    this._region_code = value;
  }

  get street_code(): string {
    return this._street_code;
  }

  set street_code(value: string) {
    this._street_code = value;
  }

  get street_name(): string {
    return this._street_name;
  }

  set street_name(value: string) {
    this._street_name = value;
  }

  get time_point(): number {
    return this._time_point;
  }

  set time_point(value: number) {
    this._time_point = value;
  }

  get submitter_name(): string {
    return this._submitter_name;
  }

  set submitter_name(value: string) {
    this._submitter_name = value;
  }
}
