import {Component, Prop, Vue} from "vue-property-decorator";
import {Button, Cell, List} from "vant";
import RegulatorEvent from "@/biz/regulator-event";
import SuperviseEventRequest from "@/request/supervise-event-request";
import {AxiosError, AxiosResponse} from "axios";
import HttpResponseUtil from "@/http/HttpResponseUtil";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import {ToastUtil} from "@/util/toast-util";
import {LocalStorageAgent} from "@/storage/local-storage-agent";
import TimeUtil from "@/util/time-util";

@Component({
  components: {[Button.name]: Button, [List.name]: List, [Cell.name]: Cell},
  filters: {
    dataFormat(value: number): string {
      return TimeUtil.getDateYYYYMMDDHHMMSS(value).replace(' ', '\r\n');
    },
    eventType(type: number): string {
      switch (type) {
        case 1:
          return '违规投放';
        case 2:
          return '乱停放';
        case 3:
          return '运维违规';
        case 4:
          return '破损/污渍';
        case 5:
          return '停放超限';
        case 8:
          return '私设停放点';
        default:
          return '';
      }
    }
  }
})

export default class EventsView extends Vue {

  public readonly pageSize: number = 20;
  public eventList: Array<RegulatorEvent> = [];
  @Prop({default: '1,2'})
  public dealStatuses!: string;

  @Prop({default: 'false'})
  public fromThirdPart!: string;
  public loading: boolean = false;
  public finished: boolean = false;
  private nextUrl: string = '';
  public list: Array<number> = [];

  constructor() {
    super()
  }

  private created(): void {
    console.log('created');
  }

  private mounted() {
    console.log('mounted');
  }

  private requestEventList(nextUrl: string) {
    const userInfo = LocalStorageAgent.getUserInfo();
    if (userInfo == null) {
      return;
    }
    if (!nextUrl) {
      ToastUtil.showLoading('数据加载中...')
    }
    SuperviseEventRequest.getRegulatorEvents(undefined, this.dealStatuses, userInfo.realname, this.pageSize, undefined, nextUrl)
      .then((response: AxiosResponse) => {
        ToastUtil.hide();
        if (!nextUrl || nextUrl === '') {
          this.eventList = [];
          document.body.scrollIntoView();
        }
        const events = HttpResponseUtil.parseJSONArray(RegulatorEvent, response.data);
        if (events && events.length > 0) {
          this.eventList.push(...events);
        }
        let has = response.headers.hasOwnProperty("link");
        if (has) {
          this.nextUrl = HttpResponseUtil.getHeaderLink(response.headers.link);
        } else {
          this.nextUrl = '';
        }
        // 加载状态结束
        this.loading = false;
        if (!this.nextUrl || this.nextUrl === '') {
          this.finished = true;
        } else {
          this.finished = false;
        }
      }).catch((error: AxiosError) => {
      ToastUtil.hide();
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      if (error.response?.status === 422) {
        resError = '数据获取失败';
      }
      ToastUtil.showMessage(resError);
      this.loading = false;
      if (!this.nextUrl || this.nextUrl === '') {
        this.finished = true;
      } else {
        this.finished = false;
      }
    });
  }

  public onLoad() {
    this.requestEventList(this.nextUrl);
  }

  public refresh() {
    this.requestEventList('');
  }

  public onProcessClick(event: RegulatorEvent) {
    // 处理中不可点击； 只处理：1:违规投放,2:乱停放,4:破损/污渍,5:停放超限
    if (event.deal_status === 2 || event.type !== 1 && event.type !== 2 && event.type !== 4 && event.type !== 5) {
      return;
    }
    this.$router.push({
      path: '/event-process',
      query: {
        regulator_event_id: event.regulator_event_id,
        fromEventList: 'true',
      }
    })
  }

  public onDetailClick(event: RegulatorEvent) {
    this.$router.push({
      path: '/supervise-event-detail',
      query: {
        regulatorEventId: event.regulator_event_id,
        fromThirdPartEventDetail: this.fromThirdPart === 'true' ? 'true' : 'false',
      }
    })
  }
}
