import {Component, Vue} from "vue-property-decorator";
import {Checkbox, CheckboxGroup, Col, Field, Row} from "vant";
import {WxUtil} from "@/util/wx-util";
import environment from "@/environments/environment";
import {AdviceTypeDomain} from "@/domain/advice-type-domain";
import EventType from "@/biz/event-type";
import BicyclePhoto from "@/biz/bicycle-photo";
import ValueUtil from "@/util/value-util";
import {ToastUtil} from "@/util/toast-util";
import {AxiosError, AxiosResponse} from "axios";
import HttpResErrorPaser, {EntityErrorPaser} from "@/http/error/HttpResErrorPaser";
import HttpResponseUtil from "@/http/HttpResponseUtil";
import {AdviceRequest} from "@/request/advice-request";
import RegulatorRecommendEventTags from "@/biz/regulator-recommend-event-tags";
import {AmapUtil} from "@/util/amap-util";
import {ComponentDomain} from "@/domain/component-domain";
import {RouterDomain} from "@/domain/router-domain";
import store from "@/pages/store/store";
import {Route} from "vue-router";
import Error from "@/http/error/Error";
import SystemUtil from "@/util/system-util";
import ImageUtil from "@/util/image-util";
import {BicycleRequest} from "@/request/BicycleRequest";
import {WxAgent} from "@/wx/wx-agent";
import {CityCodeDomain} from "@/domain/city-code-domain";
import {DialogUtil} from "@/util/dialog-util";

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave'
]);

@Component({
  name: ComponentDomain.ADVICE_EVENT,
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Field.name]: Field
  }
})
export default class PublicAdvice extends Vue {

  public subVisible = false;
  public positionVisible = true;
  public parkingPointVisible = false;
  public parkingPointInfoVisible = false;
  public showSelectPosition = false;

  public remark: string = '';
  public eventTypeNames: EventType[] = [];
  public parkingPointArea: any = '';
  public parkingPointLimit: any = '';
  public subEventTypeName: RegulatorRecommendEventTags[] = [];
  public bicyclePhotos: BicyclePhoto[] = [];
  public defaultPhoto!: BicyclePhoto;
  public parkingPointNum: any = '';
  public lon: number = 0;
  public lat: number = 0;
  public addressName: any = '';
  public regionCode: any = '';
  public cityCode = '';

  public checkResult: string[] = [];

  public adviceType: number = AdviceTypeDomain.ADVICE_ADD_PARKING_POINT;


  private created(): void {
    this.initAndGetLocation();
  }

  private initAndGetLocation() {
    //异步加载地图
    AmapUtil.loadAMapScript(() => {
      // 高德获取定位
      AmapUtil.getLocation(null, (status: any, result: any) => {
        if (status == 'complete') {
          this.showSelectPosition = false;
          this.lon = result.position.lng;
          this.lat = result.position.lat;
          this.regionCode = result.addressComponent.adcode;
          AmapUtil.getLocationAddress([this.lon, this.lat], (result: any) => {
            this.addressName = result.regeocode.formattedAddress.replace(result.regeocode.addressComponent.province
              + result.regeocode.addressComponent.city, '');
          });
          this.cityCode = result.addressComponent.citycode;
          if (environment.production && !CityCodeDomain.isRightCity(this.cityCode)) {
            this.locationAgain();
          }
        } else {
          this.showSelectPosition = true;
          this.addressName = '定位失败';
          ToastUtil.showMessage('定位失败');
        }
      });
    });
  }

  public locationAgain() {
    DialogUtil.showCallbackConfirmDialog('提示', '已定位到其他城市，请重新定位再提交数据。',
      '重新定位', '取消提交')
      .then(() => {
          this.initAndGetLocation();
        }
      ).catch(() => {
      this.$router.go(-1);
    });
  }

  public getLocationInfo() {
    //停放点数据
    const parkingPointNum = this.$route.query.parkingPointNum;
    const parkingPointArea = this.$route.query.parkingPointArea;
    const pointLimit = this.$route.query.pointLimit;
    if (parkingPointNum != undefined && parkingPointNum != null) {
      this.parkingPointNum = parkingPointNum;
      this.parkingPointInfoVisible = true;
    }
    if (parkingPointArea != undefined && parkingPointArea != null) {
      this.parkingPointArea = parkingPointArea;
    }
    if (pointLimit != undefined && pointLimit != null) {
      this.parkingPointLimit = pointLimit;
    }
  }

  activated() {
    this.getLocationInfo();
  }

  private mounted() {
    console.log('mounted');
    //初始化配置
    WxUtil.wxConfig(!environment.production, ['chooseImage']);
    this.dealAdviceTypeData();
    this.dealPhotoData();
  }

  private dealAdviceTypeData() {
    let eventType = new EventType();
    eventType.reason = "建议增加停放点";
    eventType.isSelect = true;
    eventType.dispatchType = AdviceTypeDomain.ADVICE_ADD_PARKING_POINT;
    this.eventTypeNames.push(eventType);
    let eventType1 = new EventType();
    eventType1.reason = "停放点不合理";
    eventType1.isSelect = false;
    eventType1.dispatchType = AdviceTypeDomain.ADVICE_PARKING_POINT_ERROR;
    this.eventTypeNames.push(eventType1);
  }

  public onAdviceTypeItemClick(eventType: EventType) {
    this.eventTypeNames.find((eventType1) => {
      eventType1.isSelect = false;
    });
    eventType.isSelect = true;
    this.checkResult = [];
    this.subEventTypeName = [];
    this.adviceType = eventType.dispatchType;
    if (eventType.dispatchType === AdviceTypeDomain.ADVICE_PARKING_POINT_ERROR) {
      this.positionVisible = false;
      this.parkingPointVisible = true;
    } else if (eventType.dispatchType === AdviceTypeDomain.ADVICE_ADD_PARKING_POINT) {
      this.positionVisible = true;
      this.parkingPointVisible = false;
    }
    this.requestRegulatorEventTags(eventType.dispatchType);
  }

  public onCheckBoxChange() {
    console.log('' + this.checkResult);
  }

  private requestRegulatorEventTags(tag: number) {
    AdviceRequest.getRegulatorRecommendEventTags(tag)
      .then((response: AxiosResponse) => {
        this.subEventTypeName = HttpResponseUtil.parseJSONArray(RegulatorRecommendEventTags, response.data);
        if (ValueUtil.isArrEmpty(this.subEventTypeName)) {
          this.subVisible = false;
        } else {
          this.subVisible = true;
        }
      }).catch((error: AxiosError) => {
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      if (error.response?.status === 422) {
        resError = '数据获取失败';
      }
      ToastUtil.showMessage(resError);
    });
  }

  private dealPhotoData() {
    this.defaultPhoto = new BicyclePhoto();
    this.defaultPhoto.serverPath = '';
    this.defaultPhoto.photoPath = require("../../assets/images/icon-photo@1x.png");
    this.defaultPhoto.index = -1;
    this.bicyclePhotos.push(this.defaultPhoto);
  }

  public onTakePhotoItemClick(bicyclePhoto: BicyclePhoto) {
    this.takePhoto(bicyclePhoto);
  }

  private takePhoto(photo: BicyclePhoto) {
    WxAgent.chooseImage((res: any) => {
      const localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
      if (photo.index === -1) {
        this.bicyclePhotos.pop();//移除最后一项
        let bicyclePhoto = new BicyclePhoto();
        bicyclePhoto.photoPath = localIds[0];
        bicyclePhoto.index = this.bicyclePhotos.length;
        this.bicyclePhotos.push(bicyclePhoto);
        this.uploadImage(bicyclePhoto);
        if (this.bicyclePhotos.length < 4) {
          this.bicyclePhotos.push(this.defaultPhoto);
        }
      } else {
        photo.photoPath = localIds[0];
        this.uploadImage(photo);
      }
    }, (error: any) => {
      console.log(JSON.stringify(error));
    }, () => {
      console.log('on complete');
    });
  }


  private uploadImage(photo: BicyclePhoto) {
    WxAgent.getLocalImgData(photo.photoPath, (res: any) => {
      let localData: string = res.localData; // localData是图片的base64数据，可以用img标签显示
      if (!SystemUtil.isIOS()) {
        localData = 'data:image/jpeg/png;base64,' + localData; // localData是图片的base64数据
      }
      let timeStamp: number = new Date().getTime();
      let fileName = 'regulator_event_' + timeStamp + ".png";
      let file = ImageUtil.dataURLtoFile(localData, fileName);
      BicycleRequest.uploadFile(file, 'regulator_event').then((response: AxiosResponse<any>) => {
        photo.serverPath = response.data.img_url;
      }).catch((error: AxiosError) => {
        ToastUtil.showMessage('上传失败');
      });
    }, (error: any) => {
      console.log(JSON.stringify(error));
    }, () => {
      console.log('on complete');
    });
  }

  public onSelectPositionClick() {
    this.initAndGetLocation();
  }

  public onSelectParkingPointClick() {
    this.$router.push({
      path: '/advice-location-selector',
      query: {
        adviceType: String(this.adviceType)
      }
    })
  }

  public onConfirmClick() {
    if (ValueUtil.isStrEmpty(this.regionCode)) {
      ToastUtil.showMessage('请重新定位');
      return;
    }
    if (environment.production && !CityCodeDomain.isRightCity(this.cityCode)) {
      this.locationAgain();
      return;
    }
    if (ValueUtil.isArrEmpty(this.bicyclePhotos)) {
      ToastUtil.showMessage('请拍照');
      return;
    }
    if (this.bicyclePhotos.length == 1 && this.bicyclePhotos[0].index == -1) {
      ToastUtil.showMessage('请拍照');
    } else {
      const pics: Array<string> = [];
      let canNext = true;
      this.bicyclePhotos.filter((photo) => {
        if (photo.index == -1) {

        } else {
          if (photo.serverPath) {
            pics.push(photo.serverPath);
          } else {
            canNext = false;
          }
        }
      });
      if (!canNext) {
        ToastUtil.showMessage('图片上传中');
        return;
      }

      if (this.adviceType === AdviceTypeDomain.ADVICE_PARKING_POINT_ERROR) {
        if (ValueUtil.isArrEmpty(this.checkResult)) {
          ToastUtil.showMessage('请选择子建议类型');
          return;
        }
        if (ValueUtil.isStrEmpty(this.parkingPointNum)) {
          ToastUtil.showMessage('请选择停放点');
          return;
        }
      }
      this.createEecommendEvents(pics);
    }
  }

  public createEecommendEvents(photos: Array<string>) {
    ToastUtil.showLoading('加载中');
    AdviceRequest.createEecommendEvents(this.adviceType, photos, this.addressName, this.regionCode, this.lon, this.lat,
      this.remark, this.checkResult, this.parkingPointNum)
      .then((response: AxiosResponse) => {
          ToastUtil.hide();
          ToastUtil.showMessage('创建成功');
          this.$router.go(-1);
        }
      ).catch((error: AxiosError) => {
        ToastUtil.hide();
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, new class implements EntityErrorPaser {
          public parseUnprocessableEntity(error: Error): string {
            if (error != null) {
              return error.field + "---" + error.code;
            }
            return '创建失败';
          }
        });
        ToastUtil.showMessage(resError);
      }
    )
  }

  // 在渲染该组件的对应路由被 confirm 前调用
// 不！能！获取组件实例 `this`
// 因为当守卫执行前，组件实例还没被创建
  beforeRouteEnter(to: Route, from: Route, next: () => void): void {
    if (from.name == RouterDomain.MAIN) {
      store.dispatch('addInclude', ComponentDomain.ADVICE_EVENT);
    }
    next();
  }

// 在当前路由改变，但是该组件被复用时调用
// 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，
// 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
// 可以访问组件实例 `this`
  beforeRouteUpdate(to: Route, from: Route, next: () => void): void {
    next();
  }

// 导航离开该组件的对应路由时调用
// 可以访问组件实例 `this`
  beforeRouteLeave(to: Route, from: Route, next: () => void): void {
    if (to.name == RouterDomain.MAIN) {
      store.dispatch("removeInclude", ComponentDomain.ADVICE_EVENT)
    }
    next();
  }
}
