export class DataStatisticsDomain {

  public static readonly SELECT_BY_DAY = 0;

  public static readonly SELECT_BY_MONTH = 1;

  public static readonly SELECT_BY_YEAR = 2;

  public static readonly SELECT_START_TIME = 1;
  public static readonly SELECT_END_TIME = 2;
}
