import BicycleTypeDomain from "../domain/bicycle-type-domain";

export default class Company {
  get path(): string {
    return this._path;
  }

  set path(value: string) {
    this._path = value;
  }
  get isSelect(): boolean {
    return this._isSelect;
  }

  set isSelect(value: boolean) {
    this._isSelect = value;
  }

  get companyType(): number {
    return this._companyType;
  }

  set companyType(value: number) {
    this._companyType = value;
  }

  get companyName(): string {
    return this._companyName;
  }

  set companyName(value: string) {
    this._companyName = value;
  }

  private _companyName: string = '';
  private _companyType: number = BicycleTypeDomain.COMPANY_DIDI_TYPE;
  private _isSelect = false;
  private _path: string = '';
}
