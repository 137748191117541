export class AdviceTypeDomain {
  /**
   * 停放点不合理
   */
  public static ADVICE_PARKING_POINT_ERROR = 1;

  /**
   * 建议增加停放点
   */
  public static ADVICE_ADD_PARKING_POINT = 2;

  /**
   * 市民投诉
   */
  public static ADVICE_PUBLIC_COMPLAINTS = 3;
  /**
   * 市民建议
   */
  public static ADVICE_PUBLIC_SUGGESTIONS = 4;

  /**
   * 乱停乱放不整齐
   */
  public static PARKING_RANDOM = 5;
  /**
   * 车辆过密有淤积
   */
  public static PARKING_OVER_LIMIT = 6;


}
