import DataStatistics from "@/pages/data-statistics/data-statistics";

export class RouterDomain {

  public static readonly SUPERVISE_EVENT = "SuperviseEvent";

  public static readonly PUBLIC_ADVICE = "PublicAdvice";

  public static readonly BICYCLE_INFO = "BicycleInfo";

  public static readonly MAIN = "Main";

  public static readonly EVENTPROCESS = "EventProcess";

  public static readonly SCAN_RECORD_BICYCLE = "ScanRecordBicycle";

  public static readonly statistics = "Statistics";

  public static readonly ASSESSMENT_RANKING = "AssessmentRanking";

  public static readonly COMPANY_ASSESSMENT_RANKING = "CompanyAssessmentRanking";

  public static readonly COMPANY_COORDINATE = "CompanyCoordinate";

  public static readonly REGION_RANKING = "RegionRanking";

  public static readonly REAL_TIME_MAP = "RealTimeMap";

  public static readonly DATA_STATISTICS = "DataStatistics";

  public static readonly EARLY_WARNING = "EarlyWarning";

}
