export default class WaterMarkUtil {

  public static quality = 1;

  /**
   *
   * @param {string} imageData
   * @param {number} quality
   * @param text
   */
  public static imgAddWaterText(imageData: string, text: string, myCallback: Function) {
    //创建一个图片
    let newImage = new Image();
    newImage.src = imageData;
    newImage.setAttribute("crossOrigin", 'Anonymous');	//url为外域时需要
    let imgWidth, imgHeight;
    newImage.onload = function () {
      imgWidth = newImage.width;//图片的宽高
      imgHeight = newImage.height;

      //准备在画布上绘制图片
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      canvas.width = imgWidth;
      canvas.height = imgHeight;
      if (ctx != null) {
        //清空画布
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        //开始绘制图片到画布上
        ctx.drawImage(newImage, 0, 0, canvas.width, canvas.height);
        //绘制水印
        ctx.font = "35px microsoft yahei";
        ctx.fillStyle = "#FF4747";
        var x = 30;
        var y = 30;
        var lineheight = 45;
        var lines = text.split('\n');
        for (let j = 0; j < lines.length; j++) {
          ctx.fillText(lines[j], x, y + (j * lineheight));
        }
      }
      let newBase64 = canvas.toDataURL("image/jpeg", WaterMarkUtil.quality);
      //压缩图片大小（关键代码）
      // 获取到当前的图片的大小，然后调整成自己需要的大小，例如说需要200KB-500KB之间（可以自行设置）
      // while (newBase64.length / 1024 > 500) {
      //   WaterMarkUtil.quality -= 0.02;
      //   newBase64 = canvas.toDataURL("image/jpeg", WaterMarkUtil.quality);
      // }
      //
      // while (newBase64.length / 1024 < 200 && imageData.length / 1024 > 200) {
      //   WaterMarkUtil.quality += 0.02;
      //   newBase64 = canvas.toDataURL("image/jpeg", WaterMarkUtil.quality);
      // }
      myCallback(newBase64);//回调函数，做你想要的操作（可以自行设置）
    }
  }
}
