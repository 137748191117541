







import {Component, Vue} from "vue-property-decorator";
import store from "@/pages/store/store";

@Component
export default class Supervision extends Vue {
  constructor() {
    super();
    console.log('Supervision constructor');
  }

  public getIncludes(): Array<string> {
    // console.log("Supervision.vue getKeepalives:" + store.getters.getKeepalives);
    return store.getters.getKeepalives;
  }
}
