import { Component, Vue } from 'vue-property-decorator'
import { Button, Tabs, Tab } from 'vant'
import RegionWarning from '@/pages/early-warning/region-warning/RegionWarning.vue'
import ZombieWarning from '@/pages/early-warning/zombie-warning/ZombieWarning.vue'

@Component({ components: {[Button.name]: Button, [Tabs.name]: Tabs, [Tab.name]: Tab, 'region-warning': RegionWarning, 'zombie-warning': ZombieWarning } })
export default class EarlyWarning extends Vue {
  public tabIndex = 0;
  public tabs = [{ text: '区域预警' }, { text: '僵尸车预警' }];
  public fromThirdPart = 'false';

  constructor () {
    super()
  }

  private created (): void {
    console.log('created')
  }

  private mounted () {
    console.log('mounted')
    this.fromThirdPart = String(this.$route.query.fromThirdPart)
  }

  public onRefreshClick () {
    if (this.tabIndex === 0) {
      (this.$refs.unprocessedEventsView as any).refresh()
    } else {
      (this.$refs.processedEventsView as any).refresh()
    }
  }

  public onTabClick (index: number) {
    this.tabIndex = index
  }
}
