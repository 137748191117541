/*
统筹监管
 */
import {DataDetail} from "@/biz/data-detail";

export class NormalDataStatistics {

  public total!: number;
  public detail: Array<DataDetail> = [];

}
