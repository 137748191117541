import EventInfo from "@/biz/event-info";
import DealResult from "@/biz/deal-result";


export default class RegulatorEventDetail {
  get submitter_name(): string {
    return this._submitter_name;
  }

  set submitter_name(value: string) {
    this._submitter_name = value;
  }
  get event_source(): number {
    return this._event_source;
  }

  set event_source(value: number) {
    this._event_source = value;
  }
  get company_types(): string[] {
    return this._company_types;
  }

  set company_types(value: string[]) {
    this._company_types = value;
  }
  get regulator_event_tags(): string[] {
    return this._regulator_event_tags;
  }

  set regulator_event_tags(value: string[]) {
    this._regulator_event_tags = value;
  }

  private _regulator_event_id!: string;
  /**
   * 1:违规投放,2:乱停放,3:运维违规,4:破损/污渍
   */

  private _type!: number;
  private _regulator_event_tags!:string[];

  private _pics!: string[];

  private _meituan_sum!: number;

  private _qingju_sum!: number;

  private _haluo_sum!: number;

  private _company_types!: string[];

  private _deal_status!: number;

  private _event_time!: number;
  /**
   * 事件位置
   */

  private _event_location!: string;
  /**
   * 提交人
   */
  private _submitter!: string;
  private _submitter_name!: string;

  /**
   * 1是监管提交的，2是市民提交的
   */
  private _event_source!:number;

  private _remark!: string;

  private _event_info!: EventInfo;

  private _qingju_result!: DealResult;

  private _haluo_result!: DealResult;

  private _meituan_result!: DealResult;

  private _third_party_result!: DealResult;


  get regulator_event_id(): string {
    return this._regulator_event_id;
  }

  set regulator_event_id(value: string) {
    this._regulator_event_id = value;
  }

  get type(): number {
    return this._type;
  }

  set type(value: number) {
    this._type = value;
  }

  get pics(): string[] {
    return this._pics;
  }

  set pics(value: string[]) {
    this._pics = value;
  }

  get meituan_sum(): number {
    return this._meituan_sum;
  }

  set meituan_sum(value: number) {
    this._meituan_sum = value;
  }

  get qingju_sum(): number {
    return this._qingju_sum;
  }

  set qingju_sum(value: number) {
    this._qingju_sum = value;
  }

  get haluo_sum(): number {
    return this._haluo_sum;
  }

  set haluo_sum(value: number) {
    this._haluo_sum = value;
  }

  get deal_status(): number {
    return this._deal_status;
  }

  set deal_status(value: number) {
    this._deal_status = value;
  }

  get event_time(): number {
    return this._event_time;
  }

  set event_time(value: number) {
    this._event_time = value;
  }

  get event_location(): string {
    return this._event_location;
  }

  set event_location(value: string) {
    this._event_location = value;
  }

  get submitter(): string {
    return this._submitter;
  }

  set submitter(value: string) {
    this._submitter = value;
  }

  get remark(): string {
    return this._remark;
  }

  set remark(value: string) {
    this._remark = value;
  }

  get event_info(): EventInfo {
    return this._event_info;
  }

  set event_info(value: EventInfo) {
    this._event_info = value;
  }

  get qingju_result(): DealResult {
    return this._qingju_result;
  }

  set qingju_result(value: DealResult) {
    this._qingju_result = value;
  }

  get haluo_result(): DealResult {
    return this._haluo_result;
  }

  set haluo_result(value: DealResult) {
    this._haluo_result = value;
  }

  get meituan_result(): DealResult {
    return this._meituan_result;
  }

  set meituan_result(value: DealResult) {
    this._meituan_result = value;
  }

  get third_party_result(): DealResult {
    return this._third_party_result;
  }

  set third_party_result(value: DealResult) {
    this._third_party_result = value;
  }
}
