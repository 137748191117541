export class ComponentDomain {

  public static readonly MAIN = "main";

  public static readonly SUPERVISE_EVENT = "supervise_event";

  public static readonly BICYCLE_INFO = "bicycle-info";

  public static readonly LOCATION_SELECTOR = "LocationSelector";

  public static readonly ADVICE_LOCATION_SELECTOR = "AdviceLocationSelector";

  public static readonly ADVICE_EVENT = "advice_event";

  public static readonly REAL_TIME_MAP = "RealTimeMap";

}
