import {Component, Vue} from "vue-property-decorator";
import {Collapse, CollapseItem, DatetimePicker, DropdownItem, DropdownMenu, Icon, List, Col, Row} from "vant";
import TimeUtil from "@/util/time-util";
import {BicycleUtil} from "@/util/bicycle-util";
import BicycleTypeDomain from "@/domain/bicycle-type-domain";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import {ToastUtil} from "@/util/toast-util";
import HttpResponseUtil from "@/http/HttpResponseUtil";
import {RankingRequest} from "@/request/ranking-request";
import {RankingDomain} from "@/domain/ranking-domain";
import {RegionCompanyAssessStatistics} from "@/biz/region-company-assess-statistics";
import {RegionComAssessStatistics} from "@/biz/region-com-assess-statistics";
import {RegionCodeUtil} from "@/util/region-code-util";
import {LocalStorageAgent} from "@/storage/local-storage-agent";

@Component({
  components: {
    [DatetimePicker.name]: DatetimePicker,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Icon.name]: Icon,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Col.name]: Col,
    [Row.name]: Row
  },
  filters: {
    companyNameFormat(value: number): string {
      return BicycleUtil.getBicycleCompanyTypeName(value);
    }
  }
})
export default class RegionAssessmentRanking extends Vue {

  public showRegionPage = false;

  public showRegionRanking() {
    this.showRegionPage = true;
  }

  public hideRegionRanking() {
    this.showRegionPage = false;
  }

  public showCalendar = false;
  public pickerType: string = 'date';
  public currentDate = new Date(2021, 0, 17);
  public titleName: string = '选择年月日';
  public minDateTime = new Date(2021, 6, 1);
  public maxDateTime = new Date(2025, 10, 1);
  public menus = [
    {text: '按日', value: 0},
    {text: '按周', value: 1},
    {text: '按月', value: 2},
  ];
  public menuValue = 0;

  public selectTime = '';

  public yestodayTime = new Date().getTime() - 24 * 60 * 60 * 1000;

  public loading = false;
  public finished = false;

  public rankingList: Array<RegionCompanyAssessStatistics> = [];

  public requestTime!: number;

  public activeNames = ['1'];

  public regionCode!: string;

  private mounted() {
    this.selectDayTime();
    this.setCurrentDate(this.maxDateTime);
    this.requestTime = parseInt(String(TimeUtil.getDayZeroTime(this.currentDate)));
    this.regionCode = LocalStorageAgent.getUserInfo()!.region_code;
    this.requestData();
  }

  public getRegionName(regionCode: string) {
    return RegionCodeUtil.getRegionName(regionCode);
  }

  public setCurrentDate(currentDate: Date) {
    this.currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  }

  private requestData() {
    ToastUtil.showLoading('加载中');
    if (this.menuValue == RankingDomain.SELECT_BY_DAY) {
      RankingRequest.getCompanyStatisticsRegionByDay(this.requestTime, this.regionCode).then((response) => {
        this.rankingList = HttpResponseUtil.parseJSONArray(RegionCompanyAssessStatistics, response.data);
        ToastUtil.hide();
      }).catch((error) => {
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
        if (error.response?.status === 422) {
          resError = '数据获取失败';
        }
        if (error.response?.status !== 404) {
          ToastUtil.showMessage(resError);
        }
      });
    } else if (this.menuValue == RankingDomain.SELECT_BY_WEEK) {
      RankingRequest.getCompanyStatisticsRegionByWeek(this.requestTime, this.regionCode).then((response) => {
        ToastUtil.hide();
        this.rankingList = HttpResponseUtil.parseJSONArray(RegionCompanyAssessStatistics, response.data);
      }).catch((error) => {
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
        if (error.response?.status === 422) {
          resError = '数据获取失败';
        }
        if (error.response?.status !== 404) {
          ToastUtil.showMessage(resError);
        }
      });
    } else {
      RankingRequest.getCompanyStatisticsRegionByMonth(this.requestTime, this.regionCode).then((response) => {
        ToastUtil.hide();
        this.rankingList = HttpResponseUtil.parseJSONArray(RegionCompanyAssessStatistics, response.data);
      }).catch((error) => {
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
        if (error.response?.status === 422) {
          resError = '数据获取失败';
        }
        if (error.response?.status !== 404) {
          ToastUtil.showMessage(resError);
        }
      });
    }
  }

  public onTimeConfirm(date: Date) {
    if (this.menuValue == RankingDomain.SELECT_BY_DAY) {
      this.selectTime = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.requestTime = parseInt(String(date.getTime() / 1000));
    } else if (this.menuValue == RankingDomain.SELECT_BY_WEEK) {
      //算当天
      const selectWeekDate = TimeUtil.getFirstDayOfWeek(date);
      const selectWeekLastDate = TimeUtil.getLastDayOfWeek(date);
      this.selectTime = TimeUtil.getDateYYYY_MM_DD(selectWeekDate.getTime())
        + '-' + TimeUtil.getDateYYYY_MM_DD(selectWeekLastDate.getTime());
      this.requestTime = parseInt(String(selectWeekDate.getTime() / 1000));
    } else {
      this.selectTime = TimeUtil.getLastMonth(date);
      this.requestTime = parseInt(String(TimeUtil.getMonthFirstDayTime(date)));
    }
    this.showCalendar = false;
    console.log(date);
    this.requestData();
  }

  public onTimeCancel() {
    this.showCalendar = false;
  }

  public onMenuChange(value: number) {
    this.menuValue = value;
    if (this.menuValue == RankingDomain.SELECT_BY_DAY) {
      this.pickerType = 'date';
      this.titleName = '选择年月日';
      this.selectDayTime();
      this.setCurrentDate(this.maxDateTime);
      this.minDateTime = new Date(2021, 6, 1);
      this.requestTime = parseInt(String(this.maxDateTime.getTime() / 1000));
    } else if (this.menuValue == RankingDomain.SELECT_BY_WEEK) {
      this.pickerType = 'date';
      this.titleName = '选择年月日';
      this.maxDateTime = new Date(this.yestodayTime);
      const lastWeek = this.yestodayTime - 6 * 24 * 3600 * 1000;
      const lastWeekDate = new Date(lastWeek);
      const selectWeekDate = TimeUtil.getFirstDayOfWeek(lastWeekDate);
      this.setCurrentDate(selectWeekDate);
      this.minDateTime = new Date(2021, 6, 1);
      const selectWeekLastDate = TimeUtil.getLastDayOfWeek(lastWeekDate);
      this.selectTime = TimeUtil.getDateYYYY_MM_DD(selectWeekDate.getTime())
        + '-' + TimeUtil.getDateYYYY_MM_DD(selectWeekLastDate.getTime());
      this.requestTime = parseInt(String(selectWeekDate.getTime() / 1000));
    } else {
      this.pickerType = 'year-month';
      this.titleName = '选择年月';
      this.maxDateTime = TimeUtil.getMonthFirstDay();
      this.minDateTime = new Date(2021, 5, 1);
      this.setCurrentDate(this.maxDateTime);
      this.selectTime = TimeUtil.getLastMonth(this.maxDateTime);
      this.requestTime = parseInt(String(this.maxDateTime.getTime() / 1000));
    }
    this.requestData();
  }

  public showCalendarClick() {
    this.showCalendar = true;
  }

  private selectDayTime() {
    this.maxDateTime = new Date(this.yestodayTime);
    this.selectTime = this.maxDateTime.getFullYear() + "-" + (this.maxDateTime.getMonth() + 1) + "-" + this.maxDateTime.getDate();
  }

  public onLoad() {
    this.finished = true;

  }

  public onItemClick(item: RegionCompanyAssessStatistics) {
    item.isSelect = !item.isSelect;
  }

  public getBgcolor(childItem: RegionComAssessStatistics): string {
    switch (childItem.company_type) {
      case BicycleTypeDomain.COMPANY_DIDI_TYPE:
        return 'fraction-content-item-green';
      case BicycleTypeDomain.COMPANY_HALLO_TYPE:
        return 'fraction-content-item-blue';
      case BicycleTypeDomain.COMPANY_MoBAI_TYPE:
        return 'fraction-content-item-red';
    }
    return 'fraction-content-item-green'
  }

  public getChildMargin(index: number): string {
    if (index > 0) {
      return 'child-info-margin-left';
    }

    return '';
  }

  public getCompanyImg(companyType: number): string {
    if (companyType === BicycleTypeDomain.COMPANY_DIDI_TYPE) {
      return require('../../../assets/images/icon_qj@1x.png');
    } else if (companyType === BicycleTypeDomain.COMPANY_HALLO_TYPE) {
      return require('../../../assets/images/icon_hb@1x.png');
    } else {
      return require('../../../assets/images/icon_mb@1x.png');
    }
  }
}
