export default class RegionInfoDomain {
  public static readonly ALL_CODE = '';
  public static readonly ALL_NAME = '全部区';
  public static readonly REGIONS: Array<Object> = [
    {
      name: RegionInfoDomain.ALL_NAME,
      code: RegionInfoDomain.ALL_CODE
    },
    {
      name: '历下区',
      code: '370102'
    },
    {
      name: '市中区',
      code: '370103'
    }, {
      name: '槐荫区',
      code: '370104'
    },
    {
      name: '天桥区',
      code: '370105'
    }, {
      name: '历城区',
      code: '370112'
    }, {
      name: '长清区',
      code: '370113'
    }, {
      name: '章丘区',
      code: '370114'
    }, {
      name: '济阳区',
      code: '370115'
    }, {
      name: '莱芜区',
      code: '370116'
    }, {
      name: '钢城区',
      code: '370117'
    }, {
      name: '平阴县',
      code: '370124'
    }, {
      name: '商河县',
      code: '370126'
    }, {
      name: '高新区',
      code: '370171'
    }
  ];
}





