export default class RegulatorRecommendEventTags {
  get recommend_event_tag_id(): string {
    return this._recommend_event_tag_id;
  }

  set recommend_event_tag_id(value: string) {
    this._recommend_event_tag_id = value;
  }

  get tag_text(): string {
    return this._tag_text;
  }

  set tag_text(value: string) {
    this._tag_text = value;
  }

  get isChecked(): boolean {
    return this._isChecked;
  }

  set isChecked(value: boolean) {
    this._isChecked = value;
  }

  private _isChecked = false;


  private _tag_text: string = '';

  private _recommend_event_tag_id: string = '';

}
