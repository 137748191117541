import HttpResErrorPaser from '@/http/error/HttpResErrorPaser';
import HttpResponseUtil from '@/http/HttpResponseUtil';
import { LocalStorageAgent } from '@/storage/local-storage-agent';
import { ToastUtil } from '@/util/toast-util';
import { AxiosError, AxiosResponse } from 'axios';
import { Button, Cell, List } from 'vant';
import { Component, Vue } from 'vue-property-decorator';
import { BeianCompany } from './../../../biz/beian-company';
import { RegionalStatistics } from './../../../biz/regional-statistics';
import { EarlyWarningRequest } from './../../../request/early-waring-request';

@Component({
  components: { [Button.name]: Button, [List.name]: List, [Cell.name]: Cell },
  filters: {
    companyName(type: number): string {
      switch (type) {
        case 1:
          return '青桔'
        case 2:
          return '哈啰'
        case 3:
          return '美团'
        default:
          return ''
      }
    },
    warnLevelStyle(warnLevel: number): string {
      switch (warnLevel) {
        case 1:
          return 'normal'
        case 2:
          return 'warn'
        case 3:
          return 'error'
        default:
          return 'normal'
      }
    },
    warnLevelName(warnLevel: number): string {
      switch (warnLevel) {
        case 1:
          return '正常'
        case 2:
          return '黄色预警'
        case 3:
          return '红色预警'
        default:
          return '正常'
      }
    }
  }
})

export default class RegionWarning extends Vue {
  public readonly pageSize: number = 20;
  public regionalStatisticsList: Array<RegionalStatistics> = [];
  public companyList: Array<BeianCompany> = [];

  // 1:青桔 2:哈啰 3:美团
  private static readonly QJ_COMPANY_TYPE: number = 1
  private static readonly HL_COMPANY_TYPE: number = 2
  private static readonly MT_COMPANY_TYPE: number = 3

  public loading = false
  public finished = false
  private nextUrl = ''
  public list: Array<number> = []
  public expandItem?: RegionalStatistics

  constructor() {
    super()
  }

  private created(): void {
  }

  private mounted() {
    this.requestBeianCompanyList();
  }

  private requestregionalStatisticsList(nextUrl: string) {
    const userInfo = LocalStorageAgent.getUserInfo()
    if (userInfo == null) {
      return
    }
    if (!nextUrl) {
      ToastUtil.showLoading('数据加载中...')
    }
    EarlyWarningRequest.getRegionalStatisticsList(this.pageSize, undefined, nextUrl)
      .then((response: AxiosResponse) => {
        ToastUtil.hide()
        if (!nextUrl || nextUrl === '') {
          this.regionalStatisticsList = []
          document.body.scrollIntoView()
        }
        const statistics = HttpResponseUtil.parseJSONArray(RegionalStatistics, response.data)
        if (statistics && statistics.length > 0) {
          this.regionalStatisticsList.push(...statistics)
        }
        const has = response.headers.hasOwnProperty('link')
        if (has) {
          this.nextUrl = HttpResponseUtil.getHeaderLink(response.headers.link)
        } else {
          this.nextUrl = ''
        }
        // 加载状态结束
        this.loading = false
        if (!this.nextUrl || this.nextUrl === '') {
          this.finished = true
        } else {
          this.finished = false
        }
      }).catch((error: AxiosError) => {
        ToastUtil.hide()
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null)
        if (error.response?.status === 422) {
          resError = '数据获取失败'
        }
        ToastUtil.showMessage(resError)
        this.loading = false
        if (!this.nextUrl || this.nextUrl === '') {
          this.finished = true
        } else {
          this.finished = false
        }
      })
  }

  private requestBeianCompanyList() {
    const userInfo = LocalStorageAgent.getUserInfo()
    if (userInfo == null) {
      return
    }
    EarlyWarningRequest.getBeianCompanyList()
      .then((response: AxiosResponse) => {
        if (response.data) {
          this.companyList = HttpResponseUtil.parseJSONArray(BeianCompany, response.data)
        }
      }).catch((error: AxiosError) => {
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null)
        if (error.response?.status === 422) {
          resError = '数据获取失败'
        }
        ToastUtil.showMessage(resError)
      })
  }

  public onLoad() {
    this.requestregionalStatisticsList(this.nextUrl)
  }

  public refresh() {
    this.requestBeianCompanyList()
    this.requestregionalStatisticsList('')
  }

  public onItemExpandClick(item: RegionalStatistics) {
    if (item.expanded) {
      item.expanded = false;
    } else {
      item.expanded = true;
      // if (this.expandItem && item != this.expandItem) {
      //   this.expandItem.expanded = false;
      // }
      this.expandItem = item;
    }
  }

  public formatSeconds(time: number): string {
    const hours = Math.floor(Number(time / (60 * 60)));
    const minutes = Math.floor(Number(time % 3600 / 60));
    const formatHour = hours < 10 ? `0${hours}` : hours;
    const formatMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return formatHour + ':' + formatMinutes;
  }
}
