import { render, staticRenderFns } from "./EventSubmits.vue?vue&type=template&id=21666092&scoped=true&"
import script from "./EventSubmits.ts?vue&type=script&lang=ts&"
export * from "./EventSubmits.ts?vue&type=script&lang=ts&"
import style0 from "./EventSubmits.vue?vue&type=style&index=0&id=21666092&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21666092",
  null
  
)

export default component.exports