import { Component, Vue } from 'vue-property-decorator'
import { Cascader, Field, Popup, Loading } from 'vant'
import { StatisticsRequest } from '@/request/statistics-request'
import { AxiosError, AxiosResponse } from 'axios'
import HttpResErrorPaser from '@/http/error/HttpResErrorPaser'
import { ToastUtil } from '@/util/toast-util'
import TimeUtil from '@/util/time-util'
import RegionInfoDomain from '@/domain/region-info-domain'
import UserInfo from '@/biz/user-info'
import { LocalStorageAgent } from '@/storage/local-storage-agent'
import { RegionCodeUtil } from '@/util/region-code-util'
import { forkJoin } from 'rxjs'

@Component({
  components:
  {
    [Cascader.name]: Cascader,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Loading.name]: Loading
  },
  filters: {
    thousandsFormat(value: string): string {
      if (value === '') {
        return '--'
      } else if (value === '0') {
        return '0'
      } else {
        return Number(value).toLocaleString()
      }
    },
    toPercent(point: number) {
      let str = Number(point * 100).toFixed(1)
      str += '%'
      return str
    }
  }
})
export default class Statistics extends Vue {
  private cascaderValue = '';

  private show = false;
  // 选择输入框值
  private fieldValue: string;
  // 区编码
  private regionCode: string;

  private fieldNames: Object = {
    text: 'name',
    value: 'code',
    children: 'streets'
  }

  // 选项列表，children 代表子选项，支持多级嵌套
  private options: Array<Object> = RegionInfoDomain.REGIONS;

  // 投放单车数
  private put_count = '';
  private qj_put_count = '';
  private mt_put_count = '';
  private hl_put_count = '';

  // 停放单车数
  private parked_bicycle_count = '';
  private qj_parked_bicycle_count = '';
  private mt_parked_bicycle_count = '';
  private hl_parked_bicycle_count = '';

  // 正在骑行单车
  private bicycling_count = '';
  private qj_bicycling_count = '';
  private mt_bicycling_count = '';
  private hl_bicycling_count = '';

  // 停放点数
  private parking_point_count = '';
  // 正常停放点数
  private normal_parking_point_count = '';
  // 超限停放点数
  private abnormal_parking_point_count = '';
  // 实时入栏率
  private bicycle_in_parking_point_rate = '';
  // 青桔实时入栏率
  private qj_bicycle_in_parking_point_rate = '';
  // 美团时入栏率
  private mt_bicycle_in_parking_point_rate = '';
  // 哈啰时入栏率
  private hl_bicycle_in_parking_point_rate = '';
  // 在线运维人员
  private operation_count_personnel = '';
  private qj_operation_count_personnel = '';
  private mt_operation_count_personnel = '';
  private hl_operation_count_personnel = '';
  // 在线运维车辆
  private operation_count_vehicle = '';
  private qj_operation_count_vehicle = '';
  private mt_operation_count_vehicle = '';
  private hl_operation_count_vehicle = '';

  // 今日订单数
  private bicycle_order_count = '';
  private qj_bicycle_order_count = '';
  private mt_bicycle_order_count = '';
  private hl_bicycle_order_count = '';

  // 周转率
  private bicycle_turnover_rate = '';
  private qj_bicycle_turnover_rate = '';
  private mt_bicycle_turnover_rate = '';
  private hl_bicycle_turnover_rate = '';

  // 使用率
  private bicycle_use_rate = '';
  private qj_bicycle_use_rate = '';
  private mt_bicycle_use_rate = '';
  private hl_bicycle_use_rate = '';

  private todayStartEndTimestamp: string;
  // 1:青桔 2:哈啰 3:美团
  private static readonly QJ_COMPANY_TYPE: number = 1;
  private static readonly HL_COMPANY_TYPE: number = 2;
  private static readonly MT_COMPANY_TYPE: number = 3;

  public isCityPermission = false;

  constructor() {
    super()
    this.regionCode = ''
    this.fieldValue = RegionInfoDomain.ALL_NAME
    this.todayStartEndTimestamp = Math.floor(TimeUtil.getTodayStartEndTimestamp()[0] / 1000) + ',' + Math.floor(TimeUtil.getTodayStartEndTimestamp()[1] / 1000)
  }

  public created(): void {
    const userInfo = LocalStorageAgent.getUserInfo()
    if (userInfo) {
      this.isCityPermission = UserInfo.hasCityPermission(userInfo.user_types)
      if (!this.isCityPermission) {
        this.regionCode = userInfo.region_code
      }
      this.fieldValue = RegionCodeUtil.getRegionName(userInfo.region_code)
    }
    this.refreshData()
  }

  private refreshData() {
    forkJoin(
      StatisticsRequest.getPutBicycleCount(this.todayStartEndTimestamp, this.regionCode, null),
      StatisticsRequest.getBicyclingCount(this.todayStartEndTimestamp, this.regionCode, null))
      .subscribe(res => {
        this.getPutBicycleCountSuccess(res[0])
        this.getBicyclingCountSuccess(res[1])
        if (this.put_count !== '' && this.bicycling_count !== '') {
          this.parked_bicycle_count = String(Number(this.put_count) - Number(this.bicycling_count))
          this.qj_parked_bicycle_count = String(Number(this.qj_put_count) - Number(this.qj_bicycling_count))
          this.mt_parked_bicycle_count = String(Number(this.mt_put_count) - Number(this.mt_bicycling_count))
          this.hl_parked_bicycle_count = String(Number(this.hl_put_count) - Number(this.hl_bicycling_count))

        }
      }, error => {
        this.getPutBicycleCountFalied(error)
      })
    // 获取投放单车数
    // StatisticsRequest.getPutBicycleCount(this.todayStartEndTimestamp, this.regionCode, null).then(this.getPutBicycleCountSuccess).catch(this.getPutBicycleCountFalied)
    // 获取停放单车数
    // StatisticsRequest.getParkedBicycleCount(this.todayStartEndTimestamp, this.regionCode, null).then(this.getParkedBicycleCountSuccess).catch(this.getParkedBicycleCountFalied)
    // 获取骑行中单车数量
    // StatisticsRequest.getBicyclingCount(this.todayStartEndTimestamp, this.regionCode, null).then(this.getBicyclingCountSuccess).catch(this.getBicyclingCountFalied)
    // 获取停放点数
    StatisticsRequest.getParkingPointCount(this.regionCode, null).then(this.getParkingPointCountSuccess).catch(this.getParkingPointCountFalied)
    // 获取入栏率
    StatisticsRequest.getBicycleInParkingPointRate(this.todayStartEndTimestamp, this.regionCode, null).then(this.getBicycleInParkingPointRateSuccess).catch(this.getBicycleInParkingPointRateFalied)
    // 获取运维人员数量
    StatisticsRequest.getOperationCount('1', this.todayStartEndTimestamp, this.regionCode, null).then(this.getOperationCountSuccess).catch(this.getOperationCountFalied)
    // 获取运维车辆数量
    StatisticsRequest.getOperationCount('2', this.todayStartEndTimestamp, this.regionCode, null).then(this.getOperationCountSuccess2).catch(this.getOperationCountFalied2)
    // 获取订单数
    StatisticsRequest.getBicycleOrderCount(this.todayStartEndTimestamp, this.regionCode, null).then(this.getBicycleOrderCountSuccess).catch(this.getBicycleOrderCountFalied)
    // 获取周转率
    StatisticsRequest.getBicycleTurnoverRate(this.todayStartEndTimestamp, this.regionCode, null).then(this.getBicycleTurnoverRateSuccess).catch(this.getBicycleTurnoverRateFalied)
    // 获取使用率
    StatisticsRequest.getBicycleUseRate(this.todayStartEndTimestamp, this.regionCode, null).then(this.getBicycleUseRateSuccess).catch(this.getBicycleUseRateFalied)
  }

  public getBicycleOrderCountSuccess(response: AxiosResponse): void {
    if (response.data) {
      this.bicycle_order_count = response.data.total_order_count
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type === Statistics.QJ_COMPANY_TYPE) {
            this.qj_bicycle_order_count = item.order_count
          } else if (item.company_type === Statistics.HL_COMPANY_TYPE) {
            this.hl_bicycle_order_count = item.order_count
          } else if (item.company_type === Statistics.MT_COMPANY_TYPE) {
            this.mt_bicycle_order_count = item.order_count
          }
        })
      }
    }
  }

  public getBicycleOrderCountFalied(error: AxiosError): void {
    const resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null)
    ToastUtil.showMessage(resError)
  }

  public getBicyclingCountSuccess(response: AxiosResponse): void {
    if (response.data) {
      this.bicycling_count = response.data.total_bicycling_count
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type === Statistics.QJ_COMPANY_TYPE) {
            this.qj_bicycling_count = item.bicycling_count
          } else if (item.company_type === Statistics.HL_COMPANY_TYPE) {
            this.hl_bicycling_count = item.bicycling_count
          } else if (item.company_type === Statistics.MT_COMPANY_TYPE) {
            this.mt_bicycling_count = item.bicycling_count
          }
        })
      }
    }
  }

  public getBicyclingCountFalied(error: AxiosError): void {
    const resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null)
    ToastUtil.showMessage(resError)
  }

  public getOperationCountSuccess2(response: AxiosResponse): void {
    if (response.data) {
      this.operation_count_personnel = response.data.total_operation_count
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type === Statistics.QJ_COMPANY_TYPE) {
            this.qj_operation_count_personnel = item.operation_count
          } else if (item.company_type === Statistics.HL_COMPANY_TYPE) {
            this.hl_operation_count_personnel = item.operation_count
          } else if (item.company_type === Statistics.MT_COMPANY_TYPE) {
            this.mt_operation_count_personnel = item.operation_count
          }
        })
      }
    }
  }

  public getOperationCountFalied2(error: AxiosError): void {
    const resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null)
    ToastUtil.showMessage(resError)
  }

  public getOperationCountSuccess(response: AxiosResponse): void {
    if (response.data) {
      this.operation_count_vehicle = response.data.total_operation_count
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type === Statistics.QJ_COMPANY_TYPE) {
            this.qj_operation_count_vehicle = item.operation_count
          } else if (item.company_type === Statistics.HL_COMPANY_TYPE) {
            this.hl_operation_count_vehicle = item.operation_count
          } else if (item.company_type === Statistics.MT_COMPANY_TYPE) {
            this.mt_operation_count_vehicle = item.operation_count
          }
        })
      }
    }
  }

  public getOperationCountFalied(error: AxiosError): void {
    const resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null)
    ToastUtil.showMessage(resError)
  }

  public getBicycleInParkingPointRateSuccess(response: AxiosResponse): void {
    if (response.data) {
      // 实时入栏率
      this.bicycle_in_parking_point_rate = response.data.total_parking_point_rate
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type === Statistics.QJ_COMPANY_TYPE) {
            this.qj_bicycle_in_parking_point_rate = item.parking_point_rate
          } else if (item.company_type === Statistics.HL_COMPANY_TYPE) {
            this.hl_bicycle_in_parking_point_rate = item.parking_point_rate
          } else if (item.company_type === Statistics.MT_COMPANY_TYPE) {
            this.mt_bicycle_in_parking_point_rate = item.parking_point_rate
          }
        })
      }
    }
  }

  public getBicycleInParkingPointRateFalied(error: AxiosError): void {
    const resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null)
    ToastUtil.showMessage(resError)
  }

  public getParkingPointCountSuccess(response: AxiosResponse): void {
    if (response.data) {
      response.data.forEach((item: any) => { // 1:正常,2:超限
        if (item.parking_point_use_status === 1) {
          this.normal_parking_point_count = String(item.parking_point_count)
        } else if (item.parking_point_use_status === 2) {
          this.abnormal_parking_point_count = String(item.parking_point_count)
        }
      })
      this.parking_point_count = String(Number(this.normal_parking_point_count) + Number(this.abnormal_parking_point_count))
    }
  }

  public getParkingPointCountFalied(error: AxiosError): void {
    const resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null)
    ToastUtil.showMessage(resError)
  }

  public getPutBicycleCountSuccess(response: AxiosResponse): void {
    if (response.data) {
      this.put_count = response.data.total_put_count
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type === Statistics.QJ_COMPANY_TYPE) {
            this.qj_put_count = item.put_count
          } else if (item.company_type === Statistics.HL_COMPANY_TYPE) {
            this.hl_put_count = item.put_count
          } else if (item.company_type === Statistics.MT_COMPANY_TYPE) {
            this.mt_put_count = item.put_count
          }
        })
      }
    }
  }

  public getPutBicycleCountFalied(error: AxiosError): void {
    const resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null)
    ToastUtil.showMessage(resError)
  }

  public getParkedBicycleCountSuccess(response: AxiosResponse): void {
    if (response.data) {
      this.parked_bicycle_count = response.data.total_bicycle_count
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type === Statistics.QJ_COMPANY_TYPE) {
            this.qj_parked_bicycle_count = item.bicycle_count
          } else if (item.company_type === Statistics.HL_COMPANY_TYPE) {
            this.hl_parked_bicycle_count = item.bicycle_count
          } else if (item.company_type === Statistics.MT_COMPANY_TYPE) {
            this.mt_parked_bicycle_count = item.bicycle_count
          }
        })
      }
    }
  }

  public getParkedBicycleCountFalied(error: AxiosError): void {
    const resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null)
    ToastUtil.showMessage(resError)
  }

  public getBicycleTurnoverRateSuccess(response: AxiosResponse): void {
    if (response.data) {
      // 实时入栏率
      this.bicycle_turnover_rate = response.data.total_turnover_rate
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type === Statistics.QJ_COMPANY_TYPE) {
            this.qj_bicycle_turnover_rate = item.turnover_rate
          } else if (item.company_type === Statistics.HL_COMPANY_TYPE) {
            this.hl_bicycle_turnover_rate = item.turnover_rate
          } else if (item.company_type === Statistics.MT_COMPANY_TYPE) {
            this.mt_bicycle_turnover_rate = item.turnover_rate
          }
        })
      }
    }
  }

  public getBicycleTurnoverRateFalied(error: AxiosError): void {
    const resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null)
    ToastUtil.showMessage(resError)
  }

  public getBicycleUseRateSuccess(response: AxiosResponse): void {
    if (response.data) {
      // 实时入栏率
      this.bicycle_use_rate = response.data.total_utilization_rate
      if (response.data.company_statistical) {
        response.data.company_statistical.forEach((item: any) => {
          if (item.company_type === Statistics.QJ_COMPANY_TYPE) {
            this.qj_bicycle_use_rate = item.utilization_rate
          } else if (item.company_type === Statistics.HL_COMPANY_TYPE) {
            this.hl_bicycle_use_rate = item.utilization_rate
          } else if (item.company_type === Statistics.MT_COMPANY_TYPE) {
            this.mt_bicycle_use_rate = item.utilization_rate
          }
        })
      }
    }
  }

  public getBicycleUseRateFalied(error: AxiosError): void {
    const resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null)
    ToastUtil.showMessage(resError)
  }

  public reset(): void {
    this.parked_bicycle_count = ''
    this.qj_parked_bicycle_count = ''
    this.mt_parked_bicycle_count = ''
    this.hl_parked_bicycle_count = ''

    this.parking_point_count = ''
    this.normal_parking_point_count = ''
    this.abnormal_parking_point_count = ''

    this.bicycle_in_parking_point_rate = ''
    this.qj_bicycle_in_parking_point_rate = ''
    this.mt_bicycle_in_parking_point_rate = ''
    this.hl_bicycle_in_parking_point_rate = ''

    this.operation_count_personnel = ''
    this.qj_operation_count_personnel = ''
    this.mt_operation_count_personnel = ''
    this.hl_operation_count_personnel = ''

    this.operation_count_vehicle = ''
    this.qj_operation_count_vehicle = ''
    this.mt_operation_count_vehicle = ''
    this.hl_operation_count_vehicle = ''

    this.bicycling_count = ''
    this.qj_bicycling_count = ''
    this.mt_bicycling_count = ''
    this.hl_bicycling_count = ''

    this.bicycle_order_count = ''
    this.qj_bicycle_order_count = ''
    this.mt_bicycle_order_count = ''
    this.hl_bicycle_order_count = ''
  }

  public onFinish (val: any): void {
    this.show = false
    this.regionCode = val.selectedOptions[0].code
    if (this.regionCode === RegionInfoDomain.ALL_CODE) {
      this.regionCode = ''
      this.fieldValue = RegionInfoDomain.ALL_NAME
    } else {
      this.fieldValue = val.selectedOptions[0].name
    }

    this.reset();
    (this.$refs.statisticsDiv as any).scrollTo(0, 0)
    this.refreshData()
  }
}
