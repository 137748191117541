import { render, staticRenderFns } from "./region-ranking.vue?vue&type=template&id=a8baea26&scoped=true&"
import script from "./region-ranking.ts?vue&type=script&lang=ts&"
export * from "./region-ranking.ts?vue&type=script&lang=ts&"
import style0 from "./region-ranking.less?vue&type=style&index=0&id=a8baea26&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8baea26",
  null
  
)

export default component.exports