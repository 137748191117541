export default class BicyclePhoto {
  /**
   * 照片序列
   */
  private _index: number = 0;
  /**
   * bitmap
   */
  private _photoPath: string = '';

  private _serverPath: string = '';

  get index(): number {
    return this._index;
  }

  set index(value: number) {
    this._index = value;
  }

  get photoPath(): string {
    return this._photoPath;
  }

  set photoPath(value: string) {
    this._photoPath = value;
  }

  get serverPath(): string {
    return this._serverPath;
  }

  set serverPath(value: string) {
    this._serverPath = value;
  }
}
