import environment from "@/environments/environment";

export default class ImageUtil {

  public static readonly IMAGE_MAX_SIZE = 500;
  public static readonly MAX_WIDTH = 1080;

  //将base64转换为blob
  public static dataURLtoFile(dataurl: string, fileName: string): any {
    let timeStamp: number = new Date().getTime();
    let arr: Array<string> = dataurl.split(',');
    let match = arr[0].match(/:(.*?);/);
    if (match) {
      let mime = match[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, {type: mime});
    } else {
      return null;
    }
  }

  /**
   * 压缩图片
   * @param imageData
   * @param callback
   */
  public static compressImage(imageData: string, callback: Function) {
    let quality = 1;
    //创建一个图片
    let newImage = new Image();
    newImage.src = imageData;
    newImage.setAttribute("crossOrigin", 'Anonymous');	//url为外域时需要
    let imgWidth, imgHeight;
    newImage.onload = function () {
      imgWidth = Math.min(newImage.width, ImageUtil.MAX_WIDTH);//图片的宽高, 限制最大宽度为1080
      imgHeight = imgWidth * newImage.height / newImage.width;
      //准备在画布上绘制图片
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      canvas.width = imgWidth;
      canvas.height = imgHeight;
      if (ctx != null) {
        ctx.drawImage(newImage, 0, 0, canvas.width, canvas.height);
      }
      let newBase64 = canvas.toDataURL("image/jpeg", quality);
      //压缩图片大小（关键代码）
      // 获取到当前的图片的大小，然后调整成自己需要的大小，例如说需要200KB-500KB之间（可以自行设置）
      while (newBase64.length / 1024 > ImageUtil.IMAGE_MAX_SIZE) {
        quality -= 0.02;
        newBase64 = canvas.toDataURL("image/jpeg", quality);
      }
      if (callback) {
        callback(newBase64);
      }
    }
  }

  /**
   * 压缩图片
   * @param imageData
   * @param callback
   */
  public static compressImageFile(imageFile: File, callback: Function) {
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = function () {
      const imageData = reader.result as string;
      ImageUtil.compressImage(imageData, callback)
    }
  }

  public static getPhotoPath(path: string): string {
    return environment.SHARED_BICYCLE_SERVE_URL + path;
  }
}
