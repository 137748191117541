import {Dialog} from "vant";
import {DialogAction} from "vant/types/dialog";
import ValueUtil from "@/util/value-util";

export class DialogUtil {

  /**
   * 显示无标题的对话框
   * @param message
   */
  public static showAlertDialog(message: string): Promise<DialogAction> {
    return Dialog.alert({
      message: message,
    });
  }

  /**
   * 显示带标题的对话框
   * @param message
   */
  public static showTitleAlertDialog(title: string, message: string, messageAlign: string = 'center'): Promise<DialogAction> {
    return Dialog.alert({
      title: title,
      message: message,
      messageAlign: messageAlign
    });
  }

  /**
   * 显示确认对话框
   * @param message
   */
  public static showConfirmDialog(message: string): Promise<DialogAction> {
    return Dialog.confirm({
      message: message,
    })
  }

  /**
   * 显示确认对话框
   * @param title
   * @param message
   */
  public static showTitleConfirmDialog(title: string, message: string): Promise<DialogAction> {
    return Dialog.confirm({
      title: title,
      message: message
    })
  }

  /**
   * 显示确认对话框
   * @param title
   * @param message
   * @param confirmButtonStr 确认按钮文字
   * @param cancelButtonStr 取消按钮
   */
  public static showCallbackConfirmDialog(title: string, message: string, confirmButtonStr: string,
                                          cancelButtonStr: string): Promise<DialogAction> {
    return Dialog.confirm({
      title: title,
      message: message,
      confirmButtonText: ValueUtil.isStrEmpty(confirmButtonStr) ? "确认" : confirmButtonStr,
      cancelButtonText: ValueUtil.isStrEmpty(cancelButtonStr) ? "取消" : cancelButtonStr,
    })
  }
}
