import {Component, Vue} from "vue-property-decorator";
import {Button} from "vant";
import {ToastUtil} from "@/util/toast-util";
import {AmapUtil} from "@/util/amap-util";
import MapZoomDomain from "@/domain/map-zoom-domain";
import {BicycleRequest} from "@/request/BicycleRequest";
import {AxiosError, AxiosResponse} from "axios";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import HttpResponseUtil from "@/http/HttpResponseUtil";
import ParkingPoint from "@/biz/parking-point";
import {Route} from "vue-router";
import {ComponentDomain} from "@/domain/component-domain";
import {LocalStorageAgent} from "@/storage/local-storage-agent";

const PARKING_ICON = require('../../assets/images/icon-map-parking.png');
const PARKING_DISABLE_ICON = require('../../assets/images/icon-map-parking-disable.png');
const CENTER_LOCATION_ICON = require('../../assets/images/icon-center-location.png');

class Location {
  public lng!: number;
  public lat!: number;

  constructor(lng: number, lat: number) {
    this.lng = lng;
    this.lat = lat;
  }
}

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave'
]);

@Component({
  name: ComponentDomain.LOCATION_SELECTOR,
  components: {[Button.name]: Button}
})

export default class RealTimeMap extends Vue {

  public readonly ZOOM = MapZoomDomain.MAP_ZOOM_19;
  private map: any;
  public centerLocation: Location | null = null;
  public parkingPointList: Array<ParkingPoint> = [];
  public markerList: Array<any> = [];
  public polygons: Array<any> = [];
  public curParkingPoint: ParkingPoint | null = null;
  public curPointPolygon: any;
  public curPointMarker: any;
  public requestPromise?: any;
  public firstLocation = true;
  public centerMarker?: any;
  public isNormal = false;

  constructor() {
    super()
  }

  private created(): void {
    ToastUtil.showLoading('加载中...');
    //异步加载地图
    AmapUtil.loadAMapScript(() => {
      this.map = new AMap.Map('container');
      this.map.setZoom(this.ZOOM); //设置地图层级
      this.map.on('complete', () => {
        // 地图图块加载完成后触发
        ToastUtil.hide();
        this.updateLocation();
        console.log('on map load complete');
      });
      this.addMapListeners();
    });
  }

  private mounted() {
  }

  private beforeDestroy() {
    this.removeMapListeners();
    if (this.map) {
      this.map.destroy();
      this.map = null;
    }
  }

  /**
   * 点击定位图标
   */
  public onLocateClick(): void {
    this.updateLocation();
  }

  private updateLocation() {
    if (this.map) {
      // 高德获取定位
      const tempFirstLocation = this.firstLocation;
      this.firstLocation = false;
      AmapUtil.getLocation(tempFirstLocation ? this.map : null, (status: any, result: any) => {
        // console.log("result:" + JSON.stringify(result));
        if (status == 'complete') {
          this.centerLocation = new Location(result.position.lng, result.position.lat);
          this.map.setZoom(this.ZOOM);
          this.map.setCenter([result.position.lng, result.position.lat]);
          this.addCenterMarker();
          this.requestParkingPoints();
        } else {
          ToastUtil.showMessage('定位失败');
        }
      });
    }
  }

  public addCenterMarker() {
    if (this.centerLocation && !this.centerMarker) {
      const marker = AmapUtil.getOffetMarker(this.centerLocation.lat, this.centerLocation.lng, CENTER_LOCATION_ICON, [-17, -33]);
      this.map.add(marker);
      this.centerMarker = marker;
    }
  }

  private requestParkingPoints() {
    if (!this.centerLocation) {
      return;
    }
    const requestPromise = BicycleRequest.getParkingPoints(
      String(this.centerLocation.lng),
      String(this.centerLocation.lat),
      null,
      null,
      true,
      1, LocalStorageAgent.getUserInfo()!.region_code, 1)
      .then((response: AxiosResponse) => {
        if (this.requestPromise !== requestPromise) {
          return;
        }
        const tempParkingPoints = HttpResponseUtil.parseJSONArray(ParkingPoint, response.data)
        if (tempParkingPoints) {
          this.parkingPointList = tempParkingPoints;
        }
        this.drawParkingPoints();
      }).catch((error: AxiosError) => {
        if (this.requestPromise !== requestPromise) {
          return;
        }
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      });
    this.requestPromise = requestPromise;
  }

  private drawParkingPoints() {
    this.clearPointMarkers();
    this.clearPointPolygons();
    const zoom = this.map.getZoom(); //获取当前地图级别
    if (zoom < MapZoomDomain.MAP_ZOOM_18) {
      this.drawPointMarkers();
    } else {
      this.drawPointPolygons();
    }
  }

  public drawPointPolygons() {
    for (const parkingPoint of this.parkingPointList) {
      const selected = this.curParkingPoint && this.curParkingPoint.parking_point_id === parkingPoint.parking_point_id;
      this.createPointPolygon(parkingPoint, selected);
    }
  }

  private createPointPolygon(parkingPoint: ParkingPoint, selected: any): any {
    const path = ParkingPoint.parseScopePath(parkingPoint.parking_point_scope);
    const polygon = new AMap.Polygon({
      path: path,
      strokeColor: parkingPoint.parking_point_use_status === 1 ? '#0DBF22' : '#FF0000',// 线条颜色
      strokeWeight: 1,
      strokeOpacity: 0.2,
      fillOpacity: selected ? 1.0 : 0.4,
      fillColor: parkingPoint.parking_point_use_status === 1 ? '#0DBF22' : '#FF0000',// 多边形填充颜色
      borderWeight: 1, // 线条宽度，默认为 1
      zIndex: 50,
    });
    polygon.on('click', (e: any) => {
      //连续选中同一个图标
      if (this.curParkingPoint && this.curPointPolygon &&
        this.curParkingPoint.parking_point_id === parkingPoint.parking_point_id) {
        return;
      }
      // 先把之前选中的清除选中效果
      if (this.curParkingPoint && this.curPointPolygon) {
        this.map.remove(this.curPointPolygon);
        const index = this.polygons.indexOf(this.curPointPolygon);
        this.polygons.splice(index, 1)
        this.createPointPolygon(this.curParkingPoint, false);
      }
      // 清除选中点位未点击效果，增加选中效果
      this.curParkingPoint = parkingPoint;
      this.map.remove(polygon);
      const index = this.polygons.indexOf(polygon);
      this.polygons.splice(index, 1)
      this.createPointPolygon(parkingPoint, true);
    });
    this.map.add(polygon);
    this.polygons.push(polygon);
    if (selected) {
      this.curPointPolygon = polygon;
    }
    return polygon;
  }

  public clearPointPolygons() {
    for (const polygon of this.polygons) {
      this.map.remove(polygon);
    }
    this.polygons = [];
    if (this.curPointPolygon) {
      this.map.remove(this.curPointPolygon);
    }

    this.curPointPolygon = null;
  }

  public drawPointMarkers() {
    for (const parkingPoint of this.parkingPointList) {
      const selected = this.curParkingPoint && this.curParkingPoint.parking_point_id === parkingPoint.parking_point_id;
      this.createPointMarker(parkingPoint, selected);
    }
  }

  private createPointMarker(parkingPoint: ParkingPoint, selected: any): any {
    const path = ParkingPoint.parseScopePath(parkingPoint.parking_point_scope);
    const iconPath = parkingPoint.parking_point_use_status === 1 ? PARKING_ICON : PARKING_DISABLE_ICON;
    var icon = new AMap.Icon({
      size: new AMap.Size(34, 46),    // 图标尺寸
      image: iconPath,  // Icon的图像
      imageSize: new AMap.Size(selected ? 34 : 34 * 0.7, selected ? 46 : 46 * 0.7)   // 根据所设置的大小拉伸或压缩图片
    });

    // 将 Icon 实例添加到 marker 上:
    const marker = new AMap.Marker({
      position: new AMap.LngLat(path[0][0], path[0][1]),
      icon: icon,
      offset: new AMap.Pixel(selected ? -18 : -18 * 0.7, selected ? -46 : -46 * 0.7,)
    });
    marker.on('click', (e: any) => {
      //连续选中同一个图标
      if (this.curParkingPoint && this.curPointMarker &&
        this.curParkingPoint.parking_point_id === parkingPoint.parking_point_id) {
        return;
      }
      // 先把之前选中的清除选中效果
      if (this.curParkingPoint && this.curPointMarker) {
        this.map.remove(this.curPointMarker);
        const index = this.markerList.indexOf(this.curPointMarker);
        this.markerList.splice(index, 1)
        this.createPointMarker(this.curParkingPoint, false);
      }
      // 清除选中点位未点击效果，增加选中效果
      this.curParkingPoint = parkingPoint;
      this.map.remove(marker);
      const index = this.markerList.indexOf(marker);
      this.markerList.splice(index, 1)
      this.createPointMarker(parkingPoint, true);
    });
    this.map.add(marker);
    this.markerList.push(marker);
    if (selected) {
      this.curPointMarker = marker;
    }
    return marker;
  }


  public clearPointMarkers() {
    for (const marker of this.markerList) {
      this.map.remove(marker);
    }
    this.markerList = [];
    if (this.curPointMarker) {
      this.map.remove(this.curPointMarker);
    }
    this.curPointMarker = null;

  }

  public showInfoDragStart() {
    console.log('showInfoDragStart');
  }

  public showInfoDragging() {
    console.log('showInfoDragging');
    if (this.map && this.centerMarker) {
      const center = this.map.getCenter(); //获取当前地图中心位置
      this.centerMarker.setPosition([center.lng, center.lat]);
    }
  }

  public showInfoDragEnd() {
    console.log('showInfoDragEnd');
    const center = this.map.getCenter(); //获取当前地图中心位置
    this.centerLocation = new Location(center.lng, center.lat);
    this.requestParkingPoints();
  }

  public addMapListeners() {
    if (this.map) {
      this.map.on('dragstart', this.showInfoDragStart);
      this.map.on('dragging', this.showInfoDragging);
      this.map.on('dragend', this.showInfoDragEnd);
    }
  }

  public removeMapListeners() {
    if (this.map) {
      this.map.off('dragstart', this.showInfoDragStart);
      this.map.off('dragging', this.showInfoDragging);
      this.map.off('dragend', this.showInfoDragEnd);
    }
  }

  public getParkingType(types: Array<number>): string {
    let str = '';
    types.map(item => {
      let name = '';
      if (item === 1) {
        name = '普通单车、';
      } else if (item === 2) {
        name = '助力车、';
      }
      str += name;
    });
    return str.substring(0, str.length - 1);
  }
}
