import BicycleTypeDomain from "@/domain/bicycle-type-domain";
import DispatchTypeDomain from "@/domain/dispatch-type-domain";
import ValueUtil from "@/util/value-util";

export class BicycleUtil {

  public static getBicycleCompanyTypeName(type: number): string {
    if (BicycleTypeDomain.COMPANY_DIDI_TYPE === type) {
      return "青桔";
    } else if (BicycleTypeDomain.COMPANY_HALLO_TYPE === type) {
      return "哈啰";
    } else if (BicycleTypeDomain.COMPANY_MoBAI_TYPE === type) {
      return "美团";
    }
    return "未知";
  }

  public static getDispatchType(dispatchType: number): string {
    if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_CAR) {
      return "调度车";
    } else if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_PERSON) {
      return "调度人";
    }
    return "";
  }

  public static getBicycleType(type: number): string {
    if (type == BicycleTypeDomain.NORMAL_BICYCLE) {
      return "单车";
    } else if (type == BicycleTypeDomain.ELECTRIC_BICYCLE) {
      return "助力车";
    }
    return "";
  }


  public static getBicycleTypes(bicycleType: Array<number>): string {
    if (!ValueUtil.isArrEmpty(bicycleType)) {
      const bicycleTypeText = bicycleType.map((item) => {
          switch (item) {
            case 1:
              return '单车';
            case 2:
              return '助力车';
            default:
              return '';
          }
        }
      );
      if (bicycleTypeText) {
        return bicycleTypeText.join('、')
      }
    }
    return '';
  }
}
