import { BeianCompany } from "./beian-company";
import { RegionAlertConfig } from "./region-alert-configure";

export class RegionalStatistics {

  // 1:青桔 2:哈啰 3:美团
  private static readonly QJ_COMPANY_TYPE: number = 1
  private static readonly HL_COMPANY_TYPE: number = 2
  private static readonly MT_COMPANY_TYPE: number = 3

  public regional_statistics_id!: string;
  public region_name!: string;
  public count!: number;
  public warning_level!: number;
  public region_alert_configure!: Array<RegionAlertConfig>;
  private qj_count = '';
  private hl_count = '';
  private mt_count = '';
  public expanded = false;
  public company_count: any = undefined; // 公司单车数量详情 {企业id : 企业单车数量}

  public getQjCount(companyList: Array<BeianCompany>): string {
    if (this.qj_count !== '') {
      return this.qj_count;
    }
    this.companyRegionalStatistics(companyList);
    return this.qj_count;
  }

  public getMtCount(companyList: Array<BeianCompany>): string {
    if (this.mt_count !== '') {
      return this.mt_count;
    }
    this.companyRegionalStatistics(companyList);
    return this.mt_count;
  }

  public getHlCount(companyList: Array<BeianCompany>): string {
    if (this.hl_count !== '') {
      return this.hl_count;
    }
    this.companyRegionalStatistics(companyList);
    return this.hl_count;
  }

  private companyRegionalStatistics(companyList: Array<BeianCompany>): void {
    if (!companyList || companyList.length === 0) {
      return
    }
    this.qj_count = '0';
    this.hl_count = '0';
    this.mt_count = '0';
    for (const key of Object.keys(this.company_count)) {
      companyList.forEach((companyItem) => {
        const company_type = companyItem.company_type;
        if (companyItem.company_id === key) {
          if (company_type == RegionalStatistics.QJ_COMPANY_TYPE) {
            this.qj_count = this.company_count[key];
          } else if (company_type == RegionalStatistics.HL_COMPANY_TYPE) {
            this.hl_count = this.company_count[key];
          } else if (company_type == RegionalStatistics.MT_COMPANY_TYPE) {
            this.mt_count = this.company_count[key];
          }
        }
      });
    }
  }
}
