import {UserRequest} from "@/request/UserRequest";
import {AxiosError, AxiosResponse} from "axios";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import UserInfo from "@/biz/user-info";
import HttpResponseUtil from "@/http/HttpResponseUtil";
import {LocalStorageAgent} from "@/storage/local-storage-agent";

export class Authenticator {

  /**
   * 鉴权
   * @param authSuccess
   * @param authFailed
   */
  public auth(authSuccess: Function, authFailed: Function): void {
    UserRequest.getUserInfo().then((res: AxiosResponse) => {
      const userInfo: UserInfo = HttpResponseUtil.parseJSONStr(UserInfo, res.data);
      LocalStorageAgent.setUserInfo(userInfo);
      if (UserInfo.hasPermission(userInfo.user_types)) {
        authSuccess();
      } else {
        authFailed();
      }
      console.log(res);
    }).catch((error: AxiosError) => {
      if (error.response?.status === HttpResErrorPaser.FORBIDDEN) {
        console.log(error);
        authFailed();
      } else {
        authFailed();
      }
    });
  }
}

const authenticator = new Authenticator();
export default authenticator;
