export class Initializer {

  /**
   * 初始化
   * @param initcallback
   */
  public init(initcallback: Function) {
    console.log('Initializer init');
    initcallback();
  }
}

const initializer = new Initializer();
export default initializer;
