export default class StorageDomain {

  public static readonly USERINFO: string = "userinfo";

  public static readonly WX_USERINFO: string = "wx_userinfo";

  public static readonly WX_ACCESSAUTH: string = "wx_accessauth";

  public static readonly PHOTOPATH: string = 'photopath';

  public static readonly SUB_EVENT_TYPES: string = 'subEventTypes';

  public static readonly LASTED_ACCOUNT: string = 'lastedAccount'
}
