/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosagent from '@/http/axios_agent'
import HttpRequestUtil from '@/http/HttpRequestUtil'

export class EarlyWarningRequest {
  /**
    * 获取区域预警列表
    */
  public static getRegionalStatisticsList(
    pageSize?: number,
    pageNum?: number, nextUrl?: string) {
    if (nextUrl) {
      return axiosagent.shared_bicycle_service.get(nextUrl)
    }
    const paramMap = new Map<string, any>()
    paramMap.set('page_size', pageSize)
    paramMap.set('page_num', pageNum)
    return axiosagent.shared_bicycle_service.get('api/regional_statistics?' + HttpRequestUtil.encodeParameters(paramMap))
  }

  /**
   * 获取区域预警详情
   */
  public static getRegionalStatisticsDetail(
    regionalStatisticsId: string) {
    return axiosagent.shared_bicycle_service.get(`api/regional_statistics/${regionalStatisticsId}`)
  }

  /**
   * 获取僵尸车列表
   */
  public static getZombieBicyclesList(
    pageSize?: number,
    pageNum?: number, nextUrl?: string) {
    if (nextUrl) {
      return axiosagent.shared_bicycle_service.get(nextUrl)
    }
    const paramMap = new Map<string, any>()
    paramMap.set('page_size', pageSize)
    paramMap.set('page_num', pageNum)
    return axiosagent.shared_bicycle_service.get('api/zombie_bicycles?' + HttpRequestUtil.encodeParameters(paramMap))
  }

  /**
   * 获取僵尸车数量
   */
  public static getZombieBicyclesCounts() {
    return axiosagent.shared_bicycle_service.get('api/zombie_bicycles/counts')
  }

  /**
   *
   * @returns 获取企业列表
   */
  public static getBeianCompanyList() {
    return axiosagent.shared_bicycle_service.get('app/beian/companies')
  }

}
