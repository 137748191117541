import {Component, Vue} from "vue-property-decorator";
import {Cell, CellGroup} from "vant";
import {LocalStorageAgent} from "@/storage/local-storage-agent";
import {UserRequest} from "@/request/UserRequest";
import {AxiosError, AxiosResponse} from "axios";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import {ToastUtil} from "@/util/toast-util";
import {DialogUtil} from "@/util/dialog-util";

@Component({
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell
  }
})
export default class User extends Vue {

  public userName: string = '';
  public userType: string = '';
  public showItem: boolean = true;

  private mounted() {
    console.log('mounted');
    let userInfo = LocalStorageAgent.getUserInfo();
    if (userInfo != null) {
      this.userName = userInfo.realname ? userInfo.realname : '';
      let userTypes = userInfo.user_types;
      // 4,统筹监管 5,区监管 6,街道监管
      userTypes.find((userType) => {
        // 4,统筹监管 5,区监管 6,街道监管
        if (userType === 4) {
          this.userType = '统筹监管';
        } else if (userType === 5) {
          this.userType = '区监管';
        } else if (userType === 8) {
          this.userType = '区普通用户';
          this.showItem = false;
        }
      });
    }
  }

  public onLogoutClick() {
    DialogUtil.showCallbackConfirmDialog('提示', '确定退出登录？', '', '')
      .then(() => {
          UserRequest.logout()
            .then((response: AxiosResponse) => {
              this.$router.go(-1);
            }).catch((error: AxiosError) => {
            let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
            if (error.response?.status === 422) {
              resError = '数据获取失败';
            }
            ToastUtil.showMessage(resError);
          });
        }
      ).catch(() => {
      console.log('on confirm catch');
    });
  }
}
