import {Component, Vue} from "vue-property-decorator";
import {Checkbox, CheckboxGroup, Col, Field, Grid, GridItem, Row} from 'vant';
import DispatchTypeDomain from "@/domain/dispatch-type-domain.ts";
import BicyclePhoto from "../../../biz/bicycle-photo";
import SuperviseEventRequest from "../../../request/supervise-event-request";
import {AxiosError, AxiosResponse} from "axios";
import HttpResErrorPaser, {EntityErrorPaser} from "../../../http/error/HttpResErrorPaser";
import {ToastUtil} from "@/util/toast-util";
import HttpResponseUtil from "../../../http/HttpResponseUtil";
import RegulatorEventTag from "../../../biz/regulator-event-tag";
import Company from "../../../biz/company";
import BicycleTypeDomain from "@/domain/bicycle-type-domain";
import {WxAgent} from "@/wx/wx-agent";
import ValueUtil from "@/util/value-util";
import {LocalStorageAgent} from "@/storage/local-storage-agent";
import EventType from "@/biz/event-type";
import {BicycleRequest} from "@/request/BicycleRequest";
import ImageUtil from "@/util/image-util";
import SystemUtil from "@/util/system-util";
import environment from "@/environments/environment";
import {WxUtil} from "@/util/wx-util";
import {Route} from "vue-router";
import store from "@/pages/store/store";
import {ComponentDomain} from "@/domain/component-domain";
import {RouterDomain} from "@/domain/router-domain";

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave'
]);
import {AmapUtil} from "@/util/amap-util";
import Error from "@/http/error/Error";
import WaterMarkUtil from "@/util/watermark-util";
import TimeUtil from "@/util/time-util";
import {DialogUtil} from "@/util/dialog-util";
import {CityCodeDomain} from "@/domain/city-code-domain";
import {CompanyDispatchInfo} from "@/biz/company-dispatch-Info";
import {BicycleUtil} from "@/util/bicycle-util";

@Component({
  name: ComponentDomain.SUPERVISE_EVENT,
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Field.name]: Field
  }
})
export default class SuperviseEvent extends Vue {

  public checkResult: string[] = [];

  public subVisible = false;
  public companyVisible = false;
  public remarkVisible = false;
  public positionVisible = false;

  public companyDispatchInfo: CompanyDispatchInfo | null = null;

  public remark: string = '';

  public eventTypeNames: EventType[] = [];

  public companys: Company[] = [];

  public addressName: any = '正在定位';
  public regionCode: any = '';
  public cityCode = '';

  private bottomBtnText: string = '下一步';

  public subEventTypeName: RegulatorEventTag[] = [];

  public bicyclePhotos: BicyclePhoto[] = [];

  public defaultPhoto!: BicyclePhoto;

  public companyType: number = -1;
  public dispatchType: number = DispatchTypeDomain.DISPATCH_TYPE_VIOLATION;
  public companyDispatchId!: string;

  public companyTypes: number[] = [];

  public lon: number = 0;
  public lat: number = 0;

  public photoLon = 0;
  public photoLat = 0;
  public photoAddress = '';
  public photoTime!: number;
  public locationTime!: number;

  public timeOutId: number = 0;

  public fromPage!: string;

  private mounted() {
    console.log('mounted');
    //初始化配置
    WxUtil.wxConfig(!environment.production, ['chooseImage']);
    this.dealData();
    this.dealCompanyData();
    this.dealPhotoData();
  }

  private dealData() {
    this.fromPage = String(this.$route.query.fromPage);
    if (this.fromPage === 'scan-record-bicycle') {
      let eventType = new EventType();
      eventType.reason = "违规投放";
      eventType.isSelect = true;
      eventType.dispatchType = DispatchTypeDomain.DISPATCH_TYPE_VIOLATION;
      this.eventTypeNames.push(eventType);
    } else if (this.fromPage === 'real-time-map') {
      let data = this.$route.query.data;
      if (data) {
        this.companyDispatchInfo = JSON.parse(String(data));
      }
      let eventType = new EventType();
      eventType.reason = "运维违规";
      eventType.isSelect = true;
      eventType.dispatchType = DispatchTypeDomain.DISPATCH_TYPE_OPERATION;
      this.eventTypeNames.push(eventType);
      this.onEventTypeItemClick(eventType);
    } else {
      let eventType = new EventType();
      eventType.reason = "违规投放";
      eventType.isSelect = true;
      eventType.dispatchType = DispatchTypeDomain.DISPATCH_TYPE_VIOLATION;
      this.eventTypeNames.push(eventType);
      let eventType1 = new EventType();
      eventType1.reason = "私设停放点";
      eventType1.isSelect = false;
      eventType1.dispatchType = DispatchTypeDomain.DISPATCH_TYPE_PARKING_VIOLATION;
      this.eventTypeNames.push(eventType1);
      let eventType2 = new EventType();
      eventType2.reason = "停放超限";
      eventType2.isSelect = false;
      eventType2.dispatchType = DispatchTypeDomain.DISPATCH_TYPE_PARKING_LIMIT;
      this.eventTypeNames.push(eventType2);
      let eventType3 = new EventType();
      eventType3.reason = "运维违规";
      eventType3.isSelect = false;
      eventType3.dispatchType = DispatchTypeDomain.DISPATCH_TYPE_OPERATION;
      this.eventTypeNames.push(eventType3);
      let eventType4 = new EventType();
      eventType4.reason = "乱停放";
      eventType4.isSelect = false;
      eventType4.dispatchType = DispatchTypeDomain.DISPATCH_TYPE_DISORDERLY;
      this.eventTypeNames.push(eventType4);
      let eventType5 = new EventType();
      eventType5.reason = "破损污渍";
      eventType5.isSelect = false;
      eventType5.dispatchType = DispatchTypeDomain.DISPATCH_TYPE_DAMAGED;
      this.eventTypeNames.push(eventType5);
    }
  }

  public onEventTypeItemClick(eventType: EventType) {
    this.eventTypeNames.find((eventType1) => {
      eventType1.isSelect = false;
    });
    eventType.isSelect = true;
    this.dispatchType = eventType.dispatchType;
    this.resetData();
    if (eventType.reason === '运维违规') {
      if (!this.companyDispatchInfo) {
        this.companyVisible = true;
      }
      this.remarkVisible = true;
      this.positionVisible = false;
      this.bottomBtnText = '提交';
      this.requestRegulatorEventTags(this.dispatchType);
    } else if (eventType.reason === '乱停放') {
      this.companyVisible = true;
      this.remarkVisible = true;
      this.positionVisible = true;
      this.bottomBtnText = '提交';
      this.requestRegulatorEventTags(this.dispatchType);
    } else if (eventType.reason === '破损污渍') {
      this.companyVisible = false;
      this.remarkVisible = false;
      this.positionVisible = false;
      this.bottomBtnText = '下一步';
      this.requestRegulatorEventTags(this.dispatchType);
    } else if (eventType.reason === '违规投放') {
      this.subVisible = false;
      this.companyVisible = false;
      this.remarkVisible = false;
      this.positionVisible = false;
      this.bottomBtnText = '下一步';
    } else if (eventType.reason === '私设停放点') {
      this.subVisible = false;
      this.companyVisible = true;
      this.remarkVisible = true;
      this.positionVisible = true;
      this.bottomBtnText = '提交';
    } else if (eventType.reason === '停放超限') {
      this.subVisible = false;
      this.companyVisible = true;
      this.remarkVisible = true;
      this.positionVisible = true;
      this.bottomBtnText = '提交';
    }
  }

  private resetData() {
    this.checkResult = [];
    this.subEventTypeName = [];
    this.companyTypes = [];
    this.companys.find((company1) => {
      company1.isSelect = false;
    });
    this.companyType = -1;
  }

  private requestRegulatorEventTags(tag: number) {
    SuperviseEventRequest.getRegulatorEventTags(tag)
      .then((response: AxiosResponse) => {
        this.subEventTypeName = HttpResponseUtil.parseJSONArray(RegulatorEventTag, response.data);
        if (ValueUtil.isArrEmpty(this.subEventTypeName)) {
          this.subVisible = false;
        } else {
          this.subVisible = true;
        }
      }).catch((error: AxiosError) => {
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      if (error.response?.status === 422) {
        resError = '数据获取失败';
      }
      ToastUtil.showMessage(resError);
    });
  }

  public onCheckBoxChange() {
    console.log('' + this.checkResult);
  }

  public onTakePhotoItemClick(bicyclePhoto: BicyclePhoto) {
    this.takePhoto(bicyclePhoto);
  }

  private takePhoto(photo: BicyclePhoto) {
    //如果定位成功，则取消持续定位
    if (!ValueUtil.isStrEmpty(this.regionCode)) {
      clearTimeout(this.timeOutId);
      this.photoLon = this.lon;
      this.photoLat = this.lat;
      this.photoAddress = this.addressName;
    } else {
      this.photoTime = new Date().getTime() / 1000;
    }
    WxAgent.chooseImage((res: any) => {
      const localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
      if (photo.index === -1) {
        this.bicyclePhotos.pop();//移除最后一项
        let bicyclePhoto = new BicyclePhoto();
        bicyclePhoto.photoPath = localIds[0];
        bicyclePhoto.index = this.bicyclePhotos.length;
        this.bicyclePhotos.push(bicyclePhoto);
        this.uploadImage(bicyclePhoto);
        if (this.bicyclePhotos.length < 4) {
          this.bicyclePhotos.push(this.defaultPhoto);
        }
      } else {
        photo.photoPath = localIds[0];
        this.uploadImage(photo);
      }
    }, (error: any) => {
      console.log(JSON.stringify(error));
    }, () => {
      console.log('on complete');
    });
  }


  private uploadImage(photo: BicyclePhoto) {
    WxAgent.getLocalImgData(photo.photoPath, (res: any) => {
      let localData: string = res.localData; // localData是图片的base64数据，可以用img标签显示
      if (!SystemUtil.isIOS()) {
        localData = 'data:image/jpeg/png;base64,' + localData; // localData是图片的base64数据
      }
      this.photoLon = this.lon;
      this.photoLat = this.lat;
      let waterText = '提交人:' + LocalStorageAgent.getUserInfo()!.realname + '\n'
        + '事件时间:' + TimeUtil.getDateYYYYMMDDHHMMSS(new Date().getTime()) + '\n'
        + '事件地点' + this.photoAddress;
      WaterMarkUtil.imgAddWaterText(localData, waterText, function (resultData: string) {
        let timeStamp: number = new Date().getTime();
        let fileName = 'regulator_event_' + timeStamp + ".png";
        let file = ImageUtil.dataURLtoFile(resultData, fileName);
        BicycleRequest.uploadFile(file, 'regulator_event').then((response: AxiosResponse<any>) => {
          photo.serverPath = response.data.img_url;
        }).catch((error: AxiosError) => {
          ToastUtil.showMessage('上传失败');
        });
      });
    }, (error: any) => {
      console.log(JSON.stringify(error));
    }, () => {
      console.log('on complete');
    });
  }

  private dealCompanyData() {
    const company1 = new Company();
    company1.companyName = '青桔';
    company1.isSelect = false;
    company1.companyType = BicycleTypeDomain.COMPANY_DIDI_TYPE;
    company1.path = require('../../../assets/images/icon_qj@1x.png');
    this.companys.push(company1);
    const company = new Company();
    company.companyName = '美团';
    company.isSelect = false;
    company.companyType = BicycleTypeDomain.COMPANY_MoBAI_TYPE;
    company.path = require('../../../assets/images/icon_mb@1x.png');
    this.companys.push(company);
    const company2 = new Company();
    company2.companyName = '哈啰';
    company2.isSelect = false;
    company2.companyType = BicycleTypeDomain.COMPANY_HALLO_TYPE;
    company2.path = require('../../../assets/images/icon_hb@1x.png');
    this.companys.push(company2);
  }

  public onCompanyItemClick(company: Company) {
    if (this.dispatchType == DispatchTypeDomain.DISPATCH_TYPE_PARKING_VIOLATION
      || this.dispatchType == DispatchTypeDomain.DISPATCH_TYPE_PARKING_LIMIT
      || this.dispatchType == DispatchTypeDomain.DISPATCH_TYPE_DISORDERLY) {
      company.isSelect = !company.isSelect;
      this.companyTypes = [];
      this.companys.find((company1) => {
        if (company1.isSelect) {
          this.companyTypes.push(company1.companyType);
        }
      });
    } else {
      this.companys.find((company1) => {
        company1.isSelect = false;
      });
      company.isSelect = true;
      this.companyType = company.companyType;
    }
  }

  private created(): void {
    this.initAndGetLocation();
  }

  public initAndGetLocation() {
//异步加载地图
    AmapUtil.loadAMapScript(() => {
      // 高德获取定位
      AmapUtil.getLocation(null, (status: any, result: any) => {
        if (status == 'complete') {
          this.lon = result.position.lng;
          this.lat = result.position.lat;
          this.regionCode = result.addressComponent.adcode;
          AmapUtil.getLocationAddress([this.lon, this.lat], (result: any) => {
            this.addressName = result.regeocode.formattedAddress.replace(result.regeocode.addressComponent.province
              + result.regeocode.addressComponent.city, '');
          });
          this.cityCode = result.addressComponent.citycode;
          if (environment.production && !CityCodeDomain.isRightCity(this.cityCode)) {
            this.locationAgain();
          } else {
            this.continueLocation();
          }
          if (ValueUtil.isStrEmpty(this.photoAddress)) {
            this.locationTime = new Date().getTime() / 1000;
          }
        } else {
          this.continueLocation();
        }
      });
    });
  }

  /**
   * 定位成功或失败后，每2s定位一次
   */
  public continueLocation() {
    this.$nextTick(() => {
      clearTimeout(this.timeOutId);
      this.timeOutId = setTimeout(() => {
        this.initAndGetLocation();
      }, 2000)
    });
  }

  public locationAgain() {
    DialogUtil.showCallbackConfirmDialog('提示', '已定位到其他城市，请重新定位再提交数据。',
      '重新定位', '取消提交')
      .then(() => {
          this.initAndGetLocation();
        }
      ).catch(() => {
      this.$router.go(-1);
    });
  }

  public onConfirmClick() {
    if (ValueUtil.isStrEmpty(this.regionCode)) {
      ToastUtil.showMessage('请重新定位');
      return;
    }
    if (environment.production && !CityCodeDomain.isRightCity(this.cityCode)) {
      this.locationAgain();
      return;
    }
    if (ValueUtil.isArrEmpty(this.bicyclePhotos)) {
      ToastUtil.showMessage('请拍照');
      return;
    }
    if (this.bicyclePhotos.length == 1 && this.bicyclePhotos[0].index == -1) {
      ToastUtil.showMessage('请拍照');
    } else {
      const pics: Array<string> = [];
      let canNext = true;
      this.bicyclePhotos.filter((photo) => {
        if (photo.index == -1) {

        } else {
          if (photo.serverPath) {
            pics.push(photo.serverPath);
          } else {
            canNext = false;
          }
        }
      });
      if (!canNext) {
        ToastUtil.showMessage('图片上传中');
        return;
      }
      if (this.dispatchType == DispatchTypeDomain.DISPATCH_TYPE_OPERATION) {
        if (this.fromPage === 'real-time-map') {
          if (this.companyDispatchInfo != null) {
            this.companyType = this.companyDispatchInfo.company_type;
            this.companyDispatchId = this.companyDispatchInfo.company_dispatch_id;
          }
        } else if (this.companyType === -1) {
          ToastUtil.showMessage('请选择企业');
          return;
        }
        if (ValueUtil.isArrEmpty(this.checkResult)) {
          ToastUtil.showMessage('请选择子事件类型');
          return;
        }
        this.createEvent(pics);
      } else if (this.dispatchType == DispatchTypeDomain.DISPATCH_TYPE_PARKING_VIOLATION
        || this.dispatchType === DispatchTypeDomain.DISPATCH_TYPE_PARKING_LIMIT) {
        if (ValueUtil.isArrEmpty(this.companyTypes)) {
          ToastUtil.showMessage('请选择企业');
          return;
        }
        //拍照时未定位成功，提交时成功了，两个时间相差大于3分钟时，提交数据时弹提示
        if (ValueUtil.isStrEmpty(this.photoAddress) && !ValueUtil.isStrEmpty(this.regionCode)) {
          let duration = this.locationTime - this.photoTime;
          if (duration > 3 * 60) {
            DialogUtil.showTitleAlertDialog('提示', '提交数据已超时，请重新拍照后提交事件。').then(() => {
                this.bicyclePhotos = [];
                this.dealPhotoData();
                this.addressName = '';
                this.photoAddress = '';
                this.initAndGetLocation();
              }
            ).catch(() => {
              console.log('on confirm catch');
            });
            return;
          }
        }
        this.checkResult = [];
        this.createEvent(pics);
      } else if (this.dispatchType === DispatchTypeDomain.DISPATCH_TYPE_DISORDERLY) {
        if (ValueUtil.isArrEmpty(this.checkResult)) {
          ToastUtil.showMessage('请选择子事件类型');
          return;
        }
        if (ValueUtil.isArrEmpty(this.companyTypes)) {
          ToastUtil.showMessage('请选择企业');
          return;
        }
        //拍照时未定位成功，提交时成功了，两个时间相差大于3分钟时，提交数据时弹提示
        if (ValueUtil.isStrEmpty(this.photoAddress) && !ValueUtil.isStrEmpty(this.regionCode)) {
          let duration = this.locationTime - this.photoTime;
          if (duration > 3 * 60) {
            DialogUtil.showTitleAlertDialog('提示', '提交数据已超时，请重新拍照后提交事件。').then(() => {
                this.bicyclePhotos = [];
                this.dealPhotoData();
                this.addressName = '';
                this.photoAddress = '';
                this.initAndGetLocation();
              }
            ).catch(() => {
              console.log('on confirm catch');
            });
            return;
          }
        }
        this.createEvent(pics);
      } else {
        LocalStorageAgent.setSubEventTypes(null);
        if (this.dispatchType === DispatchTypeDomain.DISPATCH_TYPE_DAMAGED) {
          if (ValueUtil.isArrEmpty(this.checkResult)) {
            ToastUtil.showMessage('请选择子事件类型');
            return;
          }
          LocalStorageAgent.setSubEventTypes(this.checkResult);
        }
        LocalStorageAgent.setPhotoPath(pics);
        this.$router.push({
          path: '/bicycle-info',
          query: {
            dispatchType: String(this.dispatchType)
          }
        });
      }
    }
  }

  public createEvent(photos: Array<string>) {
    ToastUtil.showLoading('正在创建事件');
    SuperviseEventRequest.createEvent(this.companyDispatchId, String(this.companyType), this.dispatchType,
      photos, null, null, null, this.addressName, this.regionCode,
      this.lon, this.lat, null, null, null, this.remark, this.checkResult, null, this.companyTypes)
      .then((response: AxiosResponse) => {
          ToastUtil.hide();
          ToastUtil.showMessage('创建成功');
          this.$router.go(-1);
        }
      ).catch((error: AxiosError) => {
        ToastUtil.hide();
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, new class implements EntityErrorPaser {
          public parseUnprocessableEntity(error: Error): string {
            if (error != null) {
              if (error.resource === 'regulator_event' && error.code === 'repeat_submit') {
                DialogUtil.showTitleAlertDialog('提示', '已有市民在此地点反馈问题，不能重复反馈。');
                return '';
              }
            }
            return '创建失败';
          }
        });
        if (!ValueUtil.isStrEmpty(resError)) {
          ToastUtil.showMessage(resError);
        }
      }
    )
  }

  private dealPhotoData() {
    this.defaultPhoto = new BicyclePhoto();
    this.defaultPhoto.serverPath = '';
    this.defaultPhoto.photoPath = require("../../../assets/images/icon-photo@1x.png");
    this.defaultPhoto.index = -1;
    this.bicyclePhotos.push(this.defaultPhoto);
  }

// 在渲染该组件的对应路由被 confirm 前调用
// 不！能！获取组件实例 `this`
// 因为当守卫执行前，组件实例还没被创建
  beforeRouteEnter(to: Route, from: Route, next: () => void): void {
    if (from.name == RouterDomain.MAIN) {
      store.dispatch('addInclude', ComponentDomain.SUPERVISE_EVENT);
    }
    next();
  }

// 在当前路由改变，但是该组件被复用时调用
// 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，
// 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
// 可以访问组件实例 `this`
  beforeRouteUpdate(to: Route, from: Route, next: () => void): void {
    next();
  }

// 导航离开该组件的对应路由时调用
// 可以访问组件实例 `this`
  beforeRouteLeave(to: Route, from: Route, next: () => void): void {
    if (to.name == RouterDomain.MAIN) {
      store.dispatch("removeInclude", ComponentDomain.SUPERVISE_EVENT)
    }
    next();
  }

  destroyed() {
    clearTimeout(this.timeOutId);
  }

  public getCompanyName(companyType: number): string {
    return BicycleUtil.getBicycleCompanyTypeName(companyType);
  }

  public getDispatchType(dispatchType: number): string {
    return BicycleUtil.getDispatchType(dispatchType);
  }
}
