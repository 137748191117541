import {Vue, Component} from "vue-property-decorator";
import {Col, Field, Row} from "vant";
import {ToastUtil} from "@/util/toast-util";
import {WxAgent} from "@/wx/wx-agent";
import environment from "@/environments/environment";
import {WxUtil} from "@/util/wx-util";
import ValueUtil from "@/util/value-util";
import {AmapUtil} from "@/util/amap-util";
import ScanResultDialog from "@/pages/record/result/scan-result-dialog.vue";
import {BicycleRequest} from "@/request/BicycleRequest";
import HttpResponseUtil from "@/http/HttpResponseUtil";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import BicycleRecordInfo from "@/biz/bicycle-record-info";
import {DialogUtil} from "@/util/dialog-util";
import Company from "@/biz/company";
import BicycleTypeDomain from "@/domain/bicycle-type-domain";
import {LocalStorageAgent} from "@/storage/local-storage-agent";
import UserInfo from "@/biz/user-info";

@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Field.name]: Field,
    'scan-result-dialog': ScanResultDialog
  }
})
export default class ScanRecordBicycle extends Vue {


  public bodyNum: string = '';
  public bicycleQrCode: string = '';
  public lon: number = 0;
  public lat: number = 0;
  public locationAddress = '';

  public companys: Company[] = [];
  public companyType = -1;

  private mounted() {
    console.log('mounted');
    //初始化配置
    WxUtil.wxConfig(!environment.production, ['scanQRCode']);
    wx.ready(function () {
      // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      console.log('wx ready');
    });

    wx.error(function (res: any) {
      console.log('wx error');
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log(JSON.stringify(res));
    });
    this.dealCompanyData();
  }

  private dealCompanyData() {
    const company1 = new Company();
    company1.companyName = '青桔';
    company1.isSelect = false;
    company1.companyType = BicycleTypeDomain.COMPANY_DIDI_TYPE;
    company1.path = require('../../assets/images/icon_qj@1x.png');
    this.companys.push(company1);
    const company = new Company();
    company.companyName = '美团';
    company.isSelect = false;
    company.companyType = BicycleTypeDomain.COMPANY_MoBAI_TYPE;
    company.path = require('../../assets/images/icon_mb@1x.png');
    this.companys.push(company);
    const company2 = new Company();
    company2.companyName = '哈啰';
    company2.isSelect = false;
    company2.companyType = BicycleTypeDomain.COMPANY_HALLO_TYPE;
    company2.path = require('../../assets/images/icon_hb@1x.png');
    this.companys.push(company2);
  }

  public onCompanyItemClick(company: Company) {
    this.companyType = company.companyType;
    this.companys.find((company1) => {
      company1.isSelect = false;
    });
    company.isSelect = true;
  }

  private created(): void {
    this.initAndGetLocation();
  }

  private initAndGetLocation() {
    //异步加载地图
    AmapUtil.loadAMapScript(() => {
      // 高德获取定位
      AmapUtil.getLocation(null, (status: any, result: any) => {
        if (status == 'complete') {
          this.lon = result.position.lng;
          this.lat = result.position.lat;
          AmapUtil.getLocationAddress([this.lon, this.lat], (result: any) => {
            this.locationAddress = result.regeocode.formattedAddress.replace(result.regeocode.addressComponent.province
              + result.regeocode.addressComponent.city, '');
          });
        } else {
          ToastUtil.showMessage('定位失败');
        }
      });
    });
  }

  public onScanCodeClick() {
    if (this.companyType === -1) {
      ToastUtil.showMessage('请选择企业');
      return;
    }
    this.scanQrCode();
  }

  private scanQrCode() {
    WxAgent.scanQRCode((res: any) => {
      this.bodyNum = '';
      this.bicycleQrCode = res.resultStr;
      this.checkRecordStatus();
    }, (error: any) => {
      console.log(JSON.stringify(error));
      ToastUtil.showMessage(JSON.stringify(error))
    }, () => {
      console.log('on complete');
    });
  }

  public checkRecordStatus() {
    if (ValueUtil.isStrEmpty(this.locationAddress)) {
      ToastUtil.showMessage('定位中');
      return;
    }
    BicycleRequest.checkBicycleRecordInfo(this.lon, this.lat, this.locationAddress, this.bicycleQrCode, this.bodyNum, this.companyType)
      .then((response) => {
        const bicycleRecordInfo = HttpResponseUtil.parseJSONStr(BicycleRecordInfo, response.data);
        if (bicycleRecordInfo.beian_status == 1) {
          (this.$refs.scanResultDialog as ScanResultDialog).$on('onBtnClick', this.onContinueClick);
          (this.$refs.scanResultDialog as ScanResultDialog).$on('onCloseClick', this.clearData);
          (this.$refs.scanResultDialog as ScanResultDialog).showDialog(bicycleRecordInfo);
        } else {
          let isNormalUser = UserInfo.hasNormalUserPermission(LocalStorageAgent.getUserInfo()!.user_types);
          if (isNormalUser) {
            DialogUtil.showTitleAlertDialog('验车结果', '未备案').then(() => {
                this.onContinueClick();
              }
            ).catch(() => {
              console.log('on confirm catch');
            });
          } else {
            DialogUtil.showCallbackConfirmDialog('验车结果', '未备案', '违规投放', '继续验证'
            ).then(() => {
                this.$router.push({path: '/supervise-event', query: {fromPage: 'scan-record-bicycle'}});
              }
            ).catch(() => {
              this.onContinueClick();
            });
          }
        }
      }).catch((error) => {
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      if (error.response?.status === 422) {
        resError = '数据获取失败';
      }
      ToastUtil.showMessage(resError);
    });
  }

  public onContinueClick() {
    if (!ValueUtil.isStrEmpty(this.bicycleQrCode)) {
      this.scanQrCode();
    }
    this.clearData();
  }

  public onSureClick() {
    if (this.companyType === -1) {
      ToastUtil.showMessage('请选择企业');
      return;
    }
    if (ValueUtil.isStrEmpty(this.bodyNum)) {
      ToastUtil.showMessage('请输入车身编码');
      return;
    }
    this.bicycleQrCode = '';
    this.checkRecordStatus();
  }


  public keyboardHandleScroll() {
    let scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
    setTimeout(function () {
      window.scrollTo(0, Math.max(scrollHeight - 1, 0));
    }, 300);
  }

  public clearData() {
    this.bicycleQrCode = '';
    this.bodyNum = '';
  }
}
