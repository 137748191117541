import { Component, Vue } from "vue-property-decorator";
import BicycleRecordInfo from "@/biz/bicycle-record-info";
import { BicycleUtil } from "@/util/bicycle-util";
import { Icon } from "vant";

@Component({
  components: { [Icon.name]: Icon }
})
export default class ScanResultDialog extends Vue {

  public showResultDialog: boolean = false;
  public bicycleCompany: string = '';
  public bicycleType: string = '';
  public bicycleHeadCode: string = '';
  public bicycleBodyCode: string = '';
  public platformCode: string = '';
  public frame_number: string = '';

  public showDialog(bicycleInfo: BicycleRecordInfo) {
    this.showResultDialog = true;
    this.bicycleCompany = BicycleUtil.getBicycleCompanyTypeName(bicycleInfo.company_type);
    this.bicycleType = BicycleUtil.getBicycleType(bicycleInfo.bicycle_type);
    this.bicycleHeadCode = bicycleInfo.body_num;
    this.bicycleBodyCode = bicycleInfo.body_num_two;
    this.platformCode = bicycleInfo.platform_qrcode;
    this.frame_number = bicycleInfo.frame_number;
  }

  //继续验车
  public onBtnClick() {
    this.showResultDialog = false;
    this.$emit('onBtnClick');
  }

  public onCloseClick() {
    this.showResultDialog = false;
    this.$emit('onCloseClick');
  }
}
