export class AmapUtil {
  /**
   * 加载高德地图脚本
   * @param onLoad
   */
  public static loadAMapScript(onLoad: Function): void {
    // @ts-ignore
    window.onLoad = onLoad;
    let url = 'https://webapi.amap.com/maps?v=1.4.15&key=f20dc4d0caec0be4a149d77c6551bcdf&callback=onLoad';
    let jsapi = document.createElement('script');
    jsapi.charset = 'utf-8';
    jsapi.src = url;
    document.head.appendChild(jsapi);
  }

  /**
   * 获取定位信息
   * @param map
   * @param callback
   */
  public static getLocation(map: any, callback: Function): void {
    AMap.plugin('AMap.Geolocation', function () {
      let geolocation = new AMap.Geolocation({
        enableHighAccuracy: true, // 是否使用高精度定位，默认:truetimeout: 10000,          //超过10秒后停止定位，默认：无穷大
        maximumAge: 0, // 定位结果缓存0毫秒，默认：0
        convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
        showButton: false, // 显示定位按钮，默认：true
        buttonPosition: 'LB', // 定位按钮停靠位置，默认：'LB'，左下角
        buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
        showMarker: true, // 定位成功后在定位到的位置显示点标记，默认：true
        showCircle: false, // 定位成功后用圆圈表示定位精度范围，默认：true
        panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
        zoomToAccuracy: false, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        noIpLocate: 1 // 禁止ip定位
      });
      if (map) {
        map.addControl(geolocation);
      }
      geolocation.getCurrentPosition(callback);
    });
  }

  /**
   * 根据经纬度获取地址
   * @param {number[]} lnglat
   * @param {Function} callback
   */
  public static getLocationAddress(lnglat: number[], callback: Function) {
    AMap.plugin('AMap.Geocoder', function () {
      let geocoder = new AMap.Geocoder();// 默认全国
      geocoder.getAddress(lnglat, function (status: any, result: any) {
        if (status === 'complete' && result.info === 'OK') {
          // result为对应的地理位置详细信息
          callback(result);
        }
      });
    });
  }

  /**
   * 获取makker
   * @param iconPath
   * @param lat
   * @param lon
   */
  public static getMarker(lat: number, lon: number, iconPath: string | null): any {
    if (!iconPath) {
      iconPath = "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png";
    }
    return new AMap.Marker({
      icon: iconPath,
      position: [lon, lat]
    });
  }

  /**
   * 获取makker
   * @param iconPath
   * @param lat
   * @param lon
   */
  public static getOffetMarker(lat: number, lon: number, iconPath: string | null, offest: Array<number>): any {
    if (!iconPath) {
      iconPath = "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png";
    }
    return new AMap.Marker({
      icon: iconPath,
      position: [lon, lat],
      offset: new AMap.Pixel(offest[0], offest[1])
    });
  }

  public static getPolygon(path: Array<any>): any {
    return new AMap.Polygon({
      path: path,
      strokeColor: "#0DBF22", // 线条颜色
      strokeWeight: 1,
      strokeOpacity: 0.2,
      fillOpacity: 0.4,
      fillColor: '#0DBF22', // 多边形填充颜色
      borderWeight: 1, // 线条宽度，默认为 1
      zIndex: 50
    });
  }

  /**
   * gps 坐标转换
   * @param gpsLatLng
   * @param callback
   */
  public static convertFrom(gpsLatLng: Array<number>, callback: Function): void {
    AMap.convertFrom(gpsLatLng, 'gps', callback);
  }

  /**
   * 计算两点实际距离
   * @returns {number}
   */
  public static getDistance(point1: Array<number>, point2: Array<number>): number {
    return AMap.GeometryUtil.distance(point1, point2);
  }
}
