import { ZombieBicycles } from '@/biz/zombie-bicycles';
import HttpResErrorPaser from '@/http/error/HttpResErrorPaser';
import HttpResponseUtil from '@/http/HttpResponseUtil';
import { LocalStorageAgent } from '@/storage/local-storage-agent';
import { ToastUtil } from '@/util/toast-util';
import { AxiosError, AxiosResponse } from 'axios';
import { Button, Cell, List } from 'vant';
import { Component, Vue } from 'vue-property-decorator';
import { EarlyWarningRequest } from './../../../request/early-waring-request';

@Component({
  components: { [Button.name]: Button, [List.name]: List, [Cell.name]: Cell },
  filters: {
    companyName(type: number): string {
      switch (type) {
        case 1:
          return '青桔'
        case 2:
          return '哈啰'
        case 3:
          return '美团'
        default:
          return ''
      }
    }
  }
})

export default class ZombieWarning extends Vue {
  public readonly pageSize: number = 20;
  public zombieBicyclesList: Array<ZombieBicycles> = [];
  public hl_zombie_bicycle_count = '';
  public qj_zombie_bicycle_count = '';
  public mt_zombie_bicycle_count = '';

  // 1:青桔 2:哈啰 3:美团
  private static readonly QJ_COMPANY_TYPE: number = 1;
  private static readonly HL_COMPANY_TYPE: number = 2;
  private static readonly MT_COMPANY_TYPE: number = 3;

  public loading = false;
  public finished = false;
  private nextUrl = '';
  public list: Array<number> = [];

  constructor() {
    super()
  }

  private created(): void {
  }

  private mounted() {
    this.requestZombieBicyclesCount()
  }

  private requestZombieBicyclesList(nextUrl: string) {
    const userInfo = LocalStorageAgent.getUserInfo()
    if (userInfo == null) {
      return
    }
    if (!nextUrl) {
      ToastUtil.showLoading('数据加载中...')
    }
    EarlyWarningRequest.getZombieBicyclesList(this.pageSize, undefined, nextUrl)
      .then((response: AxiosResponse) => {
        ToastUtil.hide()
        if (!nextUrl || nextUrl === '') {
          this.zombieBicyclesList = []
          document.body.scrollIntoView()
        }
        const events = HttpResponseUtil.parseJSONArray(ZombieBicycles, response.data)
        if (events && events.length > 0) {
          this.zombieBicyclesList.push(...events)
        }
        const has = response.headers.hasOwnProperty('link')
        if (has) {
          this.nextUrl = HttpResponseUtil.getHeaderLink(response.headers.link)
        } else {
          this.nextUrl = ''
        }
        // 加载状态结束
        this.loading = false
        if (!this.nextUrl || this.nextUrl === '') {
          this.finished = true
        } else {
          this.finished = false
        }
      }).catch((error: AxiosError) => {
        ToastUtil.hide()
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null)
        if (error.response?.status === 422) {
          resError = '数据获取失败'
        }
        ToastUtil.showMessage(resError)
        this.loading = false
        if (!this.nextUrl || this.nextUrl === '') {
          this.finished = true
        } else {
          this.finished = false
        }
      })
  }

  private requestZombieBicyclesCount() {
    const userInfo = LocalStorageAgent.getUserInfo()
    if (userInfo == null) {
      return
    }
    EarlyWarningRequest.getZombieBicyclesCounts()
      .then((response: AxiosResponse) => {
        if (response.data) {
          response.data.forEach((item: any) => {
            if (item.company_type === ZombieWarning.QJ_COMPANY_TYPE) {
              this.qj_zombie_bicycle_count = item.zombie_count
            } else if (item.company_type === ZombieWarning.HL_COMPANY_TYPE) {
              this.hl_zombie_bicycle_count = item.zombie_count
            } else if (item.company_type === ZombieWarning.MT_COMPANY_TYPE) {
              this.mt_zombie_bicycle_count = item.zombie_count
            }
          })
        }
      }).catch((error: AxiosError) => {
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null)
        if (error.response?.status === 422) {
          resError = '数据获取失败'
        }
        ToastUtil.showMessage(resError)
      })
  }

  public onLoad() {
    this.requestZombieBicyclesList(this.nextUrl)
  }

  public refresh() {
    this.requestZombieBicyclesList('')
    this.requestZombieBicyclesCount()
  }
}
