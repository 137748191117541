export class WxAgent {

  /**
   * 扫描二维码
   */
  public static scanQRCode(successCallback: Function, failCallback: Function, completeCallback: Function | null): void {
    wx.scanQRCode({
      needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
      success: successCallback,
      fail: failCallback,
      complete: completeCallback
    });
  }

  /**
   * 通过config接口注入权限验证配置
   * @param debug
   * @param appId
   * @param timestamp
   * @param nonceStr
   * @param signature
   * @param jsApiList
   */
  public static config(debug: boolean = false, appId: string, timestamp: number, nonceStr: string, signature: string, jsApiList: Array<string>): void {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: appId, // 必填，公众号的唯一标识
      timestamp: timestamp, // 必填，生成签名的时间戳
      nonceStr: nonceStr, // 必填，生成签名的随机串
      signature: signature,// 必填，签名
      jsApiList: jsApiList // 必填，需要使用的JS接口列表
    });
  }

  /**
   * 通过ready接口处理成功验证
   *
   * config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，
   * config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，
   * 则须把相关接口放在ready函数中调用来确保正确执行。
   * 对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
   */
  public static ready(callback: Function): void {
    wx.ready(callback);
  }

  /**
   * 通过error接口处理失败验证
   * config信息验证失败会执行error函数，如签名过期导致验证失败，
   * 具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，
   * 对于SPA可以在这里更新签名。
   */
  public static error(callback: Function): void {
    wx.error(callback);
  }

  /**
   * 拍照或从手机相册中选图接口
   * @param successCallback
   * @param failCallback
   * @param completeCallback
   */
  public static chooseImage(successCallback: Function, failCallback: Function, completeCallback: Function | null): void {
    wx.chooseImage({
      count: 1, // 默认9
      sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
      sourceType: ['camera'], // ['album', 'camera'] 可以指定来源是相册还是相机，默认二者都有
      success: successCallback,
      fail: failCallback,
      complete: completeCallback
    });
  }

  /**
   * 获取地理位置接口
   * @param successCallback
   * @param failCallback
   * @param completeCallback
   */
  public static getLocation(successCallback: Function, failCallback: Function, completeCallback: Function | null): void {
    wx.getLocation({
      type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      success: successCallback,
      fail: failCallback,
      complete: completeCallback
    });
  }

  /**
   * 获取本地图片接口
   * @param localId
   * @param successCallback
   * @param failCallback
   * @param completeCallback
   */
  public static getLocalImgData(localId: string, successCallback: Function, failCallback: Function, completeCallback: Function | null): void {
    wx.getLocalImgData({
      localId: localId, // 图片的localID
      success: successCallback,
      fail: failCallback,
      complete: completeCallback
    });
  }
}
