import Company from "@/biz/company";
import BicycleTypeDomain from "@/domain/bicycle-type-domain";
import {Vue, Component} from "vue-property-decorator";
import {Col, Field, Row} from "vant";
import DispatchTypeDomain from "@/domain/dispatch-type-domain";
import SuperviseEventRequest from "@/request/supervise-event-request";
import {ToastUtil} from "@/util/toast-util";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import ValueUtil from "@/util/value-util";
import DialogBicycleCount from "@/pages//bicycle/dialog/dialog-bicycle-count.vue";
import environment from "@/environments/environment";
import {WxUtil} from "@/util/wx-util";
import {WxAgent} from "@/wx/wx-agent";
import {ComponentDomain} from "@/domain/component-domain";
import {Route} from "vue-router";
import {RouterDomain} from "@/domain/router-domain";
import store from "@/pages/store/store";

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave'
]);

@Component({
  name: ComponentDomain.BICYCLE_INFO,
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Field.name]: Field,
    'dialog-bicycle-count': DialogBicycleCount
  }
})
export default class BicycleInfo extends Vue {

  public companys: Company[] = [];
  public bodyNum: string = '';
  public bicycleQrCode: string = '';
  public companyType = -1;

  private meituanBicycles: string[] = [];
  private qingjuBicycles: string[] = [];
  private haluoBicycles: string[] = [];

  public statusTip: string = '';
  public isBicycleRecorded: boolean = false;

  public companyName = '';

  public dispatchType: number = DispatchTypeDomain.DISPATCH_TYPE_VIOLATION;

  private mounted() {
    console.log('mounted');
    //初始化配置
    WxUtil.wxConfig(!environment.production, ['scanQRCode']);
    wx.ready(function () {
      // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      console.log('wx ready');
    });

    wx.error(function (res: any) {
      console.log('wx error');
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log(JSON.stringify(res));
    });
    this.dispatchType = Number(this.$route.query.dispatchType);
    this.dealCompanyData();
  }

  private dealCompanyData() {
    const company1 = new Company();
    company1.companyName = '青桔';
    company1.isSelect = false;
    company1.companyType = BicycleTypeDomain.COMPANY_DIDI_TYPE;
    company1.path = require('../../assets/images/icon_qj@1x.png');
    this.companys.push(company1);
    const company = new Company();
    company.companyName = '美团';
    company.isSelect = false;
    company.companyType = BicycleTypeDomain.COMPANY_MoBAI_TYPE;
    company.path = require('../../assets/images/icon_mb@1x.png');
    this.companys.push(company);
    const company2 = new Company();
    company2.companyName = '哈啰';
    company2.isSelect = false;
    company2.companyType = BicycleTypeDomain.COMPANY_HALLO_TYPE;
    company2.path = require('../../assets/images/icon_hb@1x.png');
    this.companys.push(company2);
  }

  public onCompanyItemClick(company: Company) {
    this.companyType = company.companyType;
    this.companyName = company.companyName;
    this.companys.find((company1) => {
      company1.isSelect = false;
    });
    company.isSelect = true;
  }

  public onScanCodeClick() {
    if (this.companyType === -1) {
      ToastUtil.showMessage('请选择企业');
      return;
    }
    WxAgent.scanQRCode((res: any) => {
      this.bodyNum = '';
      this.bicycleQrCode = res.resultStr;
      this.checkRecordStatus();
    }, (error: any) => {
      console.log(JSON.stringify(error));
      ToastUtil.showMessage(JSON.stringify(error))
    }, () => {
      console.log('on complete');
    });
  }

  public checkRecordStatus() {
    if (this.companyType === -1) {
      ToastUtil.showMessage('请选择企业');
      return;
    }
    if (this.dispatchType == DispatchTypeDomain.DISPATCH_TYPE_VIOLATION) {
      SuperviseEventRequest.getBicycleRecordStatus(this.bodyNum, this.bicycleQrCode, this.companyType)
        .then((response) => {
          const beianStatus = response.data.beian_status;
          if (beianStatus == BicycleTypeDomain.BLUE_BICYCLE_UNRECORD) {
            this.statusTip = "验车成功，车辆未备案。";
            this.isBicycleRecorded = false;
            this.setBicycleData();
          } else {
            this.isBicycleRecorded = true;
            this.statusTip = "验车成功，车辆已备案。";
          }
          this.resetData();
        }).catch((error) => {
        let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
        if (error.response?.status === 422) {
          resError = '数据获取失败';
        }
        ToastUtil.showMessage(resError);
      });
    } else {
      this.statusTip = "验车成功";
      this.setBicycleData();
      this.resetData();
    }
  }

  public onSureClick() {
    if (this.companyType === -1) {
      ToastUtil.showMessage('请选择企业');
      return;
    }
    if (ValueUtil.isStrEmpty(this.bodyNum)) {
      ToastUtil.showMessage('请输入车身编码');
      return;
    }
    this.bicycleQrCode = '';
    this.checkRecordStatus();
  }

  private setBicycleData() {
    if (!ValueUtil.isStrEmpty(this.bicycleQrCode)) {
      if (this.companyType == BicycleTypeDomain.COMPANY_DIDI_TYPE) {
        this.qingjuBicycles.push(this.bicycleQrCode);
      } else if (this.companyType == BicycleTypeDomain.COMPANY_HALLO_TYPE) {
        this.haluoBicycles.push(this.bicycleQrCode);
      } else {
        this.meituanBicycles.push(this.bicycleQrCode);
      }
    } else {
      if (this.companyType == BicycleTypeDomain.COMPANY_DIDI_TYPE) {
        this.qingjuBicycles.push(this.bodyNum);
      } else if (this.companyType == BicycleTypeDomain.COMPANY_HALLO_TYPE) {
        this.haluoBicycles.push(this.bodyNum);
      } else {
        this.meituanBicycles.push(this.bodyNum);
      }
    }
  }

  private resetData() {
    this.bodyNum = '';
    this.bicycleQrCode = '';
    this.companyName = '';
    this.companys.find((company1) => {
      company1.isSelect = false;
    });
    this.companyType = -1;
  }

  public onNextClick() {
    (this.$refs.bicycleCountDialog as any).showDialog();
    (this.$refs.bicycleCountDialog as any).setData(this.dispatchType,
      this.meituanBicycles, this.qingjuBicycles, this.haluoBicycles);
  }

  public keyboardHandleScroll() {
    let scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
    setTimeout(function () {
      window.scrollTo(0, Math.max(scrollHeight - 1, 0));
    }, 300);
  }

  // 在渲染该组件的对应路由被 confirm 前调用
  // 不！能！获取组件实例 `this`
  // 因为当守卫执行前，组件实例还没被创建
  beforeRouteEnter(to: Route, from: Route, next: () => void): void {
    if (from.name == RouterDomain.SUPERVISE_EVENT) {
      store.dispatch('addInclude', ComponentDomain.BICYCLE_INFO);
    }
    next();
  }

  // 在当前路由改变，但是该组件被复用时调用
  // 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，
  // 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
  // 可以访问组件实例 `this`
  beforeRouteUpdate(to: Route, from: Route, next: () => void): void {
    next();
  }

  // 导航离开该组件的对应路由时调用
  // 可以访问组件实例 `this`
  beforeRouteLeave(to: Route, from: Route, next: () => void): void {
    if (to.name == RouterDomain.MAIN || to.name == RouterDomain.EVENTPROCESS) {
      store.dispatch("removeInclude", ComponentDomain.SUPERVISE_EVENT);
      store.dispatch("removeInclude", ComponentDomain.BICYCLE_INFO);
    } else if (to.name == RouterDomain.SUPERVISE_EVENT) {
      store.dispatch("removeInclude", ComponentDomain.BICYCLE_INFO);
    }
    next();
  }
}
