import axiosagent from "@/http/axios_agent";
import HttpRequestUtil from "@/http/HttpRequestUtil";
import ValueUtil from "@/util/value-util";
import {ToastUtil} from "@/util/toast-util";

export default class SuperviseEventRequest {


  /**
   * 获取事件标签
   * @param {string} eventType
   * @returns {Promise<AxiosResponse<T>>}
   */
  public static getRegulatorEventTags(eventType: number) {
    let paramMap = new Map<string, string>();
    paramMap.set('event_type', eventType.toString());
    return axiosagent.shared_bicycle_service.get('api/regulator/regulator_event_tags?'
      + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   *
   *   创建事件
   * @param {string} companyDispatchId 企业人车调度id
   * @param {string} companyType 企业单选
   * @param {number} type  违规类型
   * @param {string[]} pics
   * @param {string[]} meituanNums
   * @param {string[]} qingjuNums
   * @param {string[]} haluoNums
   * @param {string} eventLocation 地址
   * @param {string} regionCode
   * @param {number} lon
   * @param {number} lat
   * @param {number} meituanNum
   * @param {number} qingjuNum
   * @param {number} haluoNum
   * @param {string} remark
   * @param {string[]} regulatorEventEagIds 子事件标签id列表
   * @param {string[]} parkingPointNum 停放超限停放点编号
   * @param {string[]} companyTypes 私设停放点多选企业
   */
  public static createEvent(companyDispatchId: string,
                            companyType: string,
                            type: number,
                            pics: Array<string>,
                            meituanNums: any,
                            qingjuNums: any,
                            haluoNums: any,
                            eventLocation: string,
                            regionCode: string,
                            lon: number,
                            lat: number,
                            meituanNum: any,
                            qingjuNum: any,
                            haluoNum: any,
                            remark: string,
                            regulatorEventEagIds: any,
                            parkingPointNum: any,
                            companyTypes: any
  ) {
    return axiosagent.shared_bicycle_service.post('app/regulator/regulator_events', {
      type: type,
      pics: pics,
      company_type: companyType,
      meituan_nums: meituanNums,
      qingju_nums: qingjuNums,
      haluo_nums: haluoNums,
      event_location: eventLocation,
      meituan_sum: meituanNum,
      qingju_sum: qingjuNum,
      haluo_sum: haluoNum,
      company_dispatch_id: companyDispatchId,
      remark: remark,
      region_code: regionCode,
      lon: lon,
      lat: lat,
      regulator_event_tag_ids: regulatorEventEagIds,
      parking_point_num: parkingPointNum,
      company_types: companyTypes
    });
  }

  /**
   * 获取监管事件详情
   * @param {string} regulatorEventId
   * @returns {Promise<AxiosResponse<T>>}
   */
  public static getRegulatorEventDetail(regulatorEventId: string) {
    return axiosagent.shared_bicycle_service.get('app/regulator/regulator_events/' + regulatorEventId);
  }

  /**
   * 获取单车备案状态
   * @param {string} bodyNum 车身编码
   * @param {string} bicycleQrcode  单车二维码
   * @param {number} companyType
   * @returns {Promise<AxiosResponse<T>>}
   */
  public static getBicycleRecordStatus(bodyNum: string, bicycleQrcode: string, companyType: number) {
    let paramMap = new Map<string, string>();
    if (!ValueUtil.isStrEmpty(bicycleQrcode)) {
      paramMap.set('bicycle_qrcode', bicycleQrcode);
    } else {
      paramMap.set('body_num', bodyNum);
    }
    if (companyType !== -1) {
      paramMap.set('company_type', companyType.toString());
    }
    return axiosagent.shared_bicycle_service.get('app/bicycles/beian_status?'
      + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   * 获取监管事件列表
   * deal_status  Int  F  处理状态 1:未处理,2:处理中,3:已处理
   * deal_statuses  string  F  处理状态们 多个用逗号拼接
   * operator_name  string  F  操作人 #目前未处理已处理都传操作人
   * page_size  Int  F  每页条数,默认45
   * page_num  Int  F  页码，默认1
   * @returns {Promise<AxiosResponse<T>>}
   */
  public static getRegulatorEvents(deal_status?: number,
                                   deal_statuses?: string,
                                   operator_name?: string,
                                   page_size?: number,
                                   page_num?: number, nextUrl?: string) {
    if (nextUrl) {
      return axiosagent.shared_bicycle_service.get(nextUrl);
    }
    let paramMap = new Map<string, any>();
    if (deal_status) {
      paramMap.set('deal_status', deal_status);
    }
    if (deal_statuses) {
      paramMap.set('deal_statuses', deal_statuses);
    }
    if (operator_name) {
      paramMap.set('operator_name', operator_name);
    }
    paramMap.set('page_size', page_size);
    paramMap.set('page_num', page_num);
    return axiosagent.shared_bicycle_service.get('app/regulator/regulator_events?' + HttpRequestUtil.encodeParameters(paramMap))
  }

  public static getRegulatorStorageLocations(page_size?: number | null,
                                             page_num?: number | null, nextUrl?: string) {
    if (nextUrl) {
      return axiosagent.shared_bicycle_service.get(nextUrl);
    }
    let paramMap = new Map<string, any>();
    if (page_size) {
      paramMap.set('page_size', page_size);
    }
    if (page_size) {
      paramMap.set('page_num', page_num);
    }

    return axiosagent.shared_bicycle_service.get('app/regulator/regulator_storage_locations?' + HttpRequestUtil.encodeParameters(paramMap))
  }


  /**
   *  处理事件
   */
  public static dealEvent(
    regulatorEventId: string,
    storageLocationId: string | null | undefined,
    pics: Array<string> | null | undefined,
    remark: string | null | undefined
  ) {
    return axiosagent.shared_bicycle_service.post(`app/regulator/regulator_events/${regulatorEventId}/deal`, {
      regulator_storage_location_id: storageLocationId,
      deal_pics: pics,
      remark: remark,
    });
  }

  /**
   * 获取监管事件列表
   * page_size  Int  F  每页条数,默认45
   * page_num  Int  F  页码，默认1
   * @returns {Promise<AxiosResponse<T>>}
   */
  public static getSubmittedRegulatorEvents(
                                   page_size?: number,
                                   page_num?: number, nextUrl?: string) {
    if (nextUrl) {
      return axiosagent.shared_bicycle_service.get(nextUrl);
    }
    let paramMap = new Map<string, any>();
    paramMap.set('page_size', page_size);
    paramMap.set('page_num', page_num);
    return axiosagent.shared_bicycle_service.get('api/regulator/submitted_regulator_events?' + HttpRequestUtil.encodeParameters(paramMap))
  }
}
