import { render, staticRenderFns } from "./RegionWarning.vue?vue&type=template&id=5fc61634&scoped=true&"
import script from "./RegionWarning.ts?vue&type=script&lang=ts&"
export * from "./RegionWarning.ts?vue&type=script&lang=ts&"
import style0 from "./RegionWarning.vue?vue&type=style&index=0&id=5fc61634&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc61634",
  null
  
)

export default component.exports