import {Component, Vue} from "vue-property-decorator";
import {Tab, Tabs} from "vant";
import CityAssessmentRanking
  from "@/pages/company-assessment-ranking/city-assessment-ranking/city-assessment-ranking.vue";
import RegionAssessmentRanking
  from "@/pages/company-assessment-ranking/region-assessment-ranking/region-assessment-ranking.vue";

@Component({
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    'city-assessment-ranking': CityAssessmentRanking,
    'region-assessment-ranking': RegionAssessmentRanking
  }
})
export default class CompanyAssessmentRanking extends Vue {


  public onTabClick(name: string, title: string) {
    console.log(name);
    if (name === "1") {
      (this.$refs.cityAssessmentRanking as any).showCityRanking();
      (this.$refs.regionAssessmentRanking as any).hideRegionRanking();
    } else if (name === "2") {
      (this.$refs.regionAssessmentRanking as any).showRegionRanking();
      (this.$refs.cityAssessmentRanking as any).hideCityRanking();
    }
  }
}
