export default class RegulatorEventTag {
  get tag_text(): string {
    return this._tag_text;
  }

  set tag_text(value: string) {
    this._tag_text = value;
  }
  get regulator_event_tag_id(): string {
    return this._regulator_event_tag_id;
  }

  set regulator_event_tag_id(value: string) {
    this._regulator_event_tag_id = value;
  }
  get isChecked(): boolean {
    return this._isChecked;
  }

  set isChecked(value: boolean) {
    this._isChecked = value;
  }

  private _isChecked = false;


  private _tag_text: string = '';

  private _regulator_event_tag_id: string = '';

}
