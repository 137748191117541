import {Component, Vue} from "vue-property-decorator";
import {Button, Cell, Field, Grid, GridItem, List, Popover} from "vant";
import TimeUtil from "@/util/time-util";
import RegulatorStorageLocation from "@/biz/regulator-storage-location";
import SuperviseEventRequest from "@/request/supervise-event-request";
import {AxiosError, AxiosResponse} from "axios";
import HttpResponseUtil from "@/http/HttpResponseUtil";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import {ToastUtil} from "@/util/toast-util";
import {WxAgent} from "@/wx/wx-agent";
import {WxUtil} from "@/util/wx-util";
import environment from "@/environments/environment";
import ImageUtil from "@/util/image-util";
import {BicycleRequest} from "@/request/BicycleRequest";
import SystemUtil from "@/util/system-util";
import {LocalStorageAgent} from "@/storage/local-storage-agent";
import WaterMarkUtil from "@/util/watermark-util";
import {AmapUtil} from "@/util/amap-util";
import RegulatorEventDetail from "@/biz/regulator-event-detail";

class PhotoItem {
  public url?: string | null;
  public index: number = 0;

  constructor(url: string | null, index: number) {
    this.url = url;
    this.index = index;
  }
}

@Component({
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [List.name]: List,
    [Cell.name]: Cell,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Popover.name]: Popover
  },
  filters: {
    dataFormat(value: number): string {
      return TimeUtil.getDateYYYYMMDDHHMMSS(value);
    },
    eventType(type: number): string {
      switch (type) {
        case 1:
          return '违规投放';
        case 2:
          return '乱停放';
        case 3:
          return '运维违规';
        case 4:
          return '破损/污渍';
        case 5:
          return '停放超限';
        case 8:
          return '私设停放点';
        default:
          return '';
      }
    }
  }
})

export default class EventProcess extends Vue {
  public regulatorEventId = '';
  public fromEventList = false;
  public fromThirdPartEventDetail = false;
  public eventDetail: RegulatorEventDetail | null = null;
  public type = 0;
  public eventTime = 0;
  public showPopover = false;
  public popoverWidth = 100;
  public imageWidth = 305;
  public imageHeight = 200;
  public storageLocations: Array<RegulatorStorageLocation> = [];
  public nextUrl = '';
  public loading: boolean = false;
  public finished: boolean = false;
  public location: RegulatorStorageLocation | null = null;
  public photoList: Array<PhotoItem> = [new PhotoItem(null, 0)];
  public remark: string = '';
  public lon: number = 0;
  public lat: number = 0;
  public locationAddress = '';
  public regionCode: any = '';
  public photoItem!: PhotoItem;

  constructor() {
    super()
  }

  private created(): void {
    console.log('created');
    //异步加载地图
    AmapUtil.loadAMapScript(() => {
      // 高德获取定位
      AmapUtil.getLocation(null, (status: any, result: any) => {
        if (status == 'complete') {
          this.lon = result.position.lng;
          this.lat = result.position.lat;
          this.regionCode = result.addressComponent.adcode;
          AmapUtil.getLocationAddress([this.lon, this.lat], (result: any) => {
            this.locationAddress = result.regeocode.formattedAddress.replace(result.regeocode.addressComponent.province
              + result.regeocode.addressComponent.city, '');
          });
        }
      });
    });
  }

  private mounted() {
    WxUtil.wxConfig(!environment.production, ['chooseImage']);

    this.regulatorEventId = this.$route.query.regulator_event_id as any;
    if (this.$route.query.fromEventList) {
      this.fromEventList = Boolean(this.$route.query.fromEventList);
    }
    if (this.$route.query.fromThirdPartEventDetail) {
      this.fromThirdPartEventDetail = Boolean(this.$route.query.fromThirdPartEventDetail);
    }
    this.type = Number(this.$route.query.type);
    this.eventTime = Number(this.$route.query.event_time);
    this.requestEventDetail();
    this.requestStorageLocations('');
    history.pushState(null, '', document.URL);
    window.addEventListener('popstate', this.goBack);
  }

  private beforeDestroy() {
    window.removeEventListener('popstate', this.goBack);
  }

  private requestEventDetail() {
    SuperviseEventRequest.getRegulatorEventDetail(this.regulatorEventId)
      .then((response: AxiosResponse) => {
        this.eventDetail = HttpResponseUtil.parseJSONStr(RegulatorEventDetail, response.data);
        this.type = this.eventDetail.type;
        this.eventTime = this.eventDetail.event_time;
        this.$nextTick(() => {
          setTimeout(() => {
            // @ts-ignore
            this.popoverWidth = this.$refs.positionPopover.clientWidth;
            this.imageWidth = (this.popoverWidth - 10) / 2;
            this.imageHeight = this.imageWidth * 0.66;
          }, 0)
        });
      }).catch((error: AxiosError) => {
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      if (error.response?.status === 422) {
        resError = '数据获取失败';
      }
      ToastUtil.showMessage(resError);
    });
  }


  public onCommitClick() {
    if (!this.eventDetail) {
      return;
    }
    if (this.type !== 1 && (!this.photoList || this.photoList.length == 1 && !this.photoList[0].url)) {
      ToastUtil.showMessage('请拍照')
      return;
    }
    // if (!location) {
    //   ToastUtil.showMessage('请选择存放位置')
    //   return;
    // }
    const pics: Array<string> = [];
    this.photoList.filter((pic) => {
      if (pic.url) {
        pics.push(pic.url);
      }
    });
    ToastUtil.showLoading('提交中...');
    SuperviseEventRequest.dealEvent(this.regulatorEventId, this.location?.regulator_storage_location_id, pics, this.remark)
      .then((response: AxiosResponse) => {
        ToastUtil.hide();
        ToastUtil.showMessage('提交成功');
        if (this.fromEventList) {
          this.$router.go(-1);
        } else if (this.fromThirdPartEventDetail) {
          this.$router.go(-3);
        } else {
          // 退回到第一页（不使用push方式，会增加history）
          const historyLength = window.history.length;
          this.$router.go(historyLength > 1 ? -historyLength + 1 : -1);
        }

      }).catch((error: AxiosError) => {
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      if (error.response?.status === 422) {
        resError = '提交失败';
      }
      else if (error.response?.status === 404) {
        resError = '事件已处理';
      }
      ToastUtil.hide();
      ToastUtil.showMessage(resError);
    });
  }

  private requestStorageLocations(nextUrl: string) {
    SuperviseEventRequest.getRegulatorStorageLocations(null, null, this.nextUrl)
      .then((response: AxiosResponse) => {
        if (!nextUrl || nextUrl === '') {
          this.storageLocations = [];
        }
        const locations = HttpResponseUtil.parseJSONArray(RegulatorStorageLocation, response.data);
        if (locations && locations.length > 0) {
          this.storageLocations.push(...locations);
        }
        let has = response.headers.hasOwnProperty("link");
        if (has) {
          this.nextUrl = HttpResponseUtil.getHeaderLink(response.headers.link);
        } else {
          this.nextUrl = '';
        }
        // 加载状态结束
        this.loading = false;
        if (!this.nextUrl || this.nextUrl === '') {
          this.finished = true;
        } else {
          this.finished = false;
        }
      }).catch((error: AxiosError) => {
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      if (error.response?.status === 422) {
        resError = '数据获取失败';
      }
      ToastUtil.showMessage(resError);
      this.loading = false;
    });
  }

  public onLoad() {
    this.requestStorageLocations(this.nextUrl);
  }

  public onLocationClick(location: RegulatorStorageLocation) {
    this.showPopover = false;
    this.location = location;
  }

  public onPhotoClick(photo: PhotoItem) {
    this.takePhoto(photo);
  }

  private takePhoto(photo: PhotoItem) {
    this.photoItem = photo;
    WxAgent.chooseImage((res: any) => {
      const localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
      WxAgent.getLocalImgData(localIds[0], (res: any) => {
        let localData: string = res.localData; // localData是图片的base64数据，可以用img标签显示
        if (!SystemUtil.isIOS()) {
          localData = 'data:image/jpeg/png;base64,' + localData; // localData是图片的base64数据
        }
        ToastUtil.showLoading('上传图片中...');
        let waterText = '提交人:' + LocalStorageAgent.getUserInfo()!.realname + '\n'
          + '事件时间:' + TimeUtil.getDateYYYYMMDDHHMMSS(new Date().getTime()) + '\n'
          + '事件地点' + this.locationAddress;
        const onUploadImageSuccess = this.onUploadImageSuccess;
        WaterMarkUtil.imgAddWaterText(localData, waterText, function (resultData: string) {
          let timeStamp: number = new Date().getTime();
          let fileName = 'regulator_event_' + timeStamp + ".png";
          let file = ImageUtil.dataURLtoFile(resultData, fileName);
          BicycleRequest.uploadFile(file, 'regulator_event').then(onUploadImageSuccess).catch((error: AxiosError) => {
            ToastUtil.hide();
            ToastUtil.showMessage('上传失败');
          });
        });

      }, (error: any) => {
        console.log(JSON.stringify(error));
      }, () => {
        console.log('on complete');
      });
    }, (error: any) => {
      console.log(JSON.stringify(error));
    }, () => {
      console.log('on complete');
    });
  }


  public onUploadImageSuccess(response: AxiosResponse<any>) {
    ToastUtil.hide();
    if (this.photoItem) {
      this.photoItem.url = response.data.img_url;
      // 重新创建对象，避免数量不变时，不刷新UI
      const photoList: Array<PhotoItem> = [];
      photoList.push(...this.photoList);
      if (photoList.length < 4 && this.photoItem.index === photoList.length - 1) {
        photoList.push(new PhotoItem(null, photoList.length))
      }
      this.photoList = photoList;
    }
  }


  public getPhotoPath(path: string): string {
    return ImageUtil.getPhotoPath(path)
  }

  goBack() {
    this.$nextTick(() => {
      if (this.fromEventList) {
        this.$router.go(-1);
      } else if (this.fromThirdPartEventDetail) {
        this.$router.go(-2);
      } else {
        const historyLength = window.history.length;
        this.$router.go(historyLength > 1 ? -historyLength + 2 : -1);
      }
    });
  }
}
