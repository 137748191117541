import axios, {AxiosInstance} from 'axios';
import environment from "@/environments/environment";


export class AxiosAgent {

  private _axios!: AxiosInstance;

  private _shared_bicycle_service!: AxiosInstance;

  get axios(): AxiosInstance {
    if (!this._axios) {
      this._axios = axios.create({
        withCredentials: true,
        timeout: 60000
      });
    }
    return this._axios;
  }

  get shared_bicycle_service(): AxiosInstance {
    if (!this._shared_bicycle_service) {
      this._shared_bicycle_service = axios.create({
        baseURL: environment.SHARED_BICYCLE_SERVE_URL,
        withCredentials: true
      });
    }

    return this._shared_bicycle_service;
  }
}

const axiosagent = new AxiosAgent();
export default axiosagent;

