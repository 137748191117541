import dateFormat from 'dateformat';

export default class TimeUtil {

  /**
   * 通过字符串时间获取时间戳
   * @param dateStr
   * @return number
   */
  public static getTimeStampByStr(dateStr: string): number {
    return Date.parse(dateStr);
  }

  /**
   * 通过时间戳获取日期
   * @param timeStamp
   * @return string
   */
  public static getDateByTimeStamp(timeStamp: number): string {
    return new Date(timeStamp).toDateString()
  }

  /**
   * 日期格式化
   * @param timeStampOrDateStr
   * @returns string
   */
  public static getDateYYYYMMDD(timeStampOrDateStr: string | number): string {
    return dateFormat(new Date(timeStampOrDateStr), 'yyyy-mm-dd')
  }

  /**
   * 日期格式化
   * @param timeStampOrDateStr
   * @returns string
   */
  public static getDateYYYY_MM_DD(timeStampOrDateStr: string | number): string {
    return dateFormat(new Date(timeStampOrDateStr), 'yyyy/mm/dd')
  }

  /**
   * 日期格式化
   * @param timeStampOrDateStr
   * @returns string
   */
  public static getDateYYYYMMDDByFormat(timeStampOrDateStr: string | number, format: string): string {
    return dateFormat(new Date(timeStampOrDateStr), format)
  }

  /**
   * 日期格式化
   * @param timeStampOrDateStr
   * return string
   */

  public static getDateYYYYMMDDHHMMSS(timeStampOrDateStr: string | number): string {
    return dateFormat(new Date(timeStampOrDateStr), 'yyyy-mm-dd HH:MM:ss')
  }

  /**
   * 日期格式化
   * @param timeStampOrDateStr
   * @return string
   */
  public static getDateYYYYMMDDHHMM(timeStampOrDateStr: string | number) {
    return dateFormat(new Date(timeStampOrDateStr), 'yyyy-mm-dd HH:MM')
  }

  /**
   * 日期格式化
   * @param timeStampOrDateStr
   * @returns string
   */
  public static getDatemmDDHHMM(timeStampOrDateStr: string | number): string {
    return dateFormat(new Date(timeStampOrDateStr), 'mm-dd HH:MM')
  }

  /**
   * 日期格式化
   * @param timeStampOrDateStr
   * @returns string
   */
  public static getDateMM(timeStampOrDateStr: string | number): string {
    return dateFormat(new Date(timeStampOrDateStr), 'm')
  }

  /**
   * 日期格式化
   * @param timeStampOrDateStr
   * @return string
   */

  public static getDateHHMM(timeStampOrDateStr: string | number): string {
    return dateFormat(new Date(timeStampOrDateStr), 'HH:MM')
  }

  /**
   * 日期格式化
   * @param timeStampOrDateStr
   * @returns string
   */
  public static getDate(timeStampOrDateStr: string | number, format: string): string {
    return dateFormat(new Date(timeStampOrDateStr), format)
  }

  /**
   * 获取今天的开始时间和结束时间
   */
  public static getTodayStartEndTimestamp(): Array<number> {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    const today00 = today.getTime();

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    const tomorrow00 = tomorrow.getTime();

    return [today00, tomorrow00];
  }


  public static getTimeDuration(milliseconds: number): string {
    if (milliseconds < 0) {
      return ''
    } else if (milliseconds === 0) {
      return '0秒'
    } else {
      var between = Math.floor(milliseconds / 1000) // 除以1000是为了转换成秒
      var day = Math.floor(between / (24 * 3600))
      var hour = Math.floor(between % (24 * 3600) / 3600)
      var minute = Math.floor(between % 3600 / 60)
      var second = Math.ceil(between % 60)
      if (between < 60) {
        return second + '秒';
      }
      return (day > 0 ? day + '天' : '') + (hour > 0 ? hour + '小时' : '') + (minute > 0 ? minute + '分' : '') + (second < 10 ? '0' + second : second) + '秒';
    }
  }

  /**
   * 分钟转换成时间格式
   * @param value 秒
   * hh:mm  13:30
   * theTime1 分钟
   * theTime2 小时
   */
  public static FormatSecondsHHMM(value: number): string {
    const theTime = Number(parseInt(String(value)));
    let minutes = '';
    let hours = '';
    let hourTime = Number(parseInt(String(theTime / 3600)));
    let minuteTime = parseInt(String(Number(theTime % 3600) / 60));
    if (hourTime == 0) {
      hours = '00';
    } else if (hourTime < 10) {
      hours = '0' + hourTime;
    } else {
      hours = hourTime.toString();
    }
    if (minuteTime == 0) {
      minutes = "00";
    } else if (minuteTime < 10) {
      minutes = "0" + minuteTime;
    } else {
      minutes = minuteTime.toString();
    }
    return hours + ':' + minutes;
  }

  //获取上个月日期
  public static getLastMonth(date: Date): string {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month == 0) {
      year = year - 1;
      month = 12;
    }
    return year + "年" + month + "月";
  }

  public static getMonthFirstDay() {
    return new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
  }

  /**
   * 最后一天时间
   * @returns {number}
   */
  public static getMonthStartAndEnd(): Date {
    //获取当前时间
    let currentDate = new Date();
    let month = currentDate.getMonth() - 1;
    if (month < 0) {
      let n = parseInt(String((-month) / 12));
      month += n * 12;
      currentDate.setFullYear(currentDate.getFullYear() - n);
    }
    currentDate = new Date(currentDate.setMonth(month));
    //获得当前月份0-11
    let currentMonth = currentDate.getMonth();
    //获得当前年份4位年
    let currentYear = currentDate.getFullYear();
    //获得上一月的最后一天
    return new Date(currentYear, currentMonth + 1, 0);
  }

  public static getSelectDate(date: Date, showDay: boolean): string {
    return showDay ? (date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate())
      : (date.getFullYear() + '/' + (date.getMonth() + 1));
  }

  /**
   * 获取年份的第一天0晨时间戳
   */
  public static getFirstDayOfYearByPicker(year: number): number {
    let newDate = new Date();
    newDate.setFullYear(year);
    newDate.setMonth(0);
    newDate.setDate(1);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(1);
    return newDate.getTime() / 1000;
  }

  /**
   * 获取年份的最后一天0晨时间戳
   */
  public static getLastDayOfYearByPicker(year: number): number {
    let newDate = new Date();
    newDate.setFullYear(year + 1);
    newDate.setMonth(0);
    newDate.setDate(1);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    return newDate.getTime() / 1000;
  }

  /**
   * 获取年份的第一天0晨时间戳
   */
  public static getFirstDayOfYear(date: Date): number {
    let newDate = new Date(date);
    newDate.setDate(1);
    newDate.setMonth(0);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    return newDate.getTime() / 1000;
  }

  /**
   * 获取年份的最后一天0晨时间戳
   */
  public static getLastDayOfYear(date: Date): number {
    let newDate = new Date(date);
    newDate.setFullYear(date.getFullYear() + 1);
    newDate.setDate(1);
    newDate.setMonth(0);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    return newDate.getTime() / 1000;
  }

  /**
   * 获取月份第一天0点时间戳
   * @param {Date} date
   * @returns {number} 秒
   */
  public static getMonthFirstDayTime(date: Date): number {
    let newDate = new Date(date);
    newDate.setDate(1);
    newDate.setHours(0);
    newDate.setSeconds(0);
    newDate.setMinutes(0);
    return newDate.getTime() / 1000;
  }

  /**
   * 获取日期凌晨时间戳
   * @param {Date} date
   * @returns {number}
   */
  public static getDayZeroTime(date: Date) {
    let newDate = new Date(date);
    newDate.setHours(0);
    newDate.setSeconds(0);
    newDate.setMinutes(0);
    return newDate.getTime() / 1000;
  }

  /**
   * 获取下个月份0点时间戳
   * @param {Date} date
   * @returns {number}
   */
  public static getNextMonthFirstDayTime(date: Date): number {
    let newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1);
    newDate.setDate(1);
    newDate.setHours(0);
    newDate.setSeconds(0);
    newDate.setMinutes(0);
    return newDate.getTime() / 1000;
  }

  /**
   * 获取当前日期周一
   */
  public static getFirstDayOfWeek(date: Date): Date {
    let day = date.getDay() || 7;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1 - day);
  };

  /**
   * 获取当前日期周日
   */
  //获取当前日期周日
  public static getLastDayOfWeek(date: Date): Date {
    let day = date.getDay() || 7;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7 - day);
  };

  public static getYearsPicker(): Array<string> {
    let minYear = 2021;
    let years = [String(minYear)];
    let date = new Date();
    let curYear = date.getFullYear();
    if (curYear > minYear) {
      for (let j = minYear + 1; j <= curYear; j++) {
        years.push(String(j));
      }
    }
    return years;
  }
}
