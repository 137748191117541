import { render, staticRenderFns } from "./modify-pwd.vue?vue&type=template&id=6b2ccfa9&scoped=true&"
import script from "./modify-pwd.ts?vue&type=script&lang=ts&"
export * from "./modify-pwd.ts?vue&type=script&lang=ts&"
import style0 from "./modify-pwd.less?vue&type=style&index=0&id=6b2ccfa9&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b2ccfa9",
  null
  
)

export default component.exports