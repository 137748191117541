import {StopNumberInfo} from "@/biz/stop-number-info";

export default class ParkingPoint {
  public parking_point_id!: string;//	主键
  public parking_point_num!: string;//	停放点编号
  public parking_point_scope!: Array<string>;//	单车停放点范围(坐标点集合) 暂存5个坐标点,收尾一样形成闭环
  public parking_bicycle_type!: Array<number>;//	单车停放点可停放车辆类型(1-普通单车,2-助力车)
  public parking_point_status!: number;//	单车停放点状态:1-启用,2-停用
  public region_code!: string;//	区code
  public street_code!: string;//	街道code
  public street_name!: string;//	街道名称
  public point_limit!: number;//	停放点上限
  public parking_point_attribute!: number;//	停放点属性 1:标准 2:道路
  public parking_point_area!: string;//	单车停放点面积，单位:米
  public parking_point_use_status!: number;//	停放点使用状态 1:正常,2:超限
  public centre_lon!: string;//	中心点经度
  public centre_lat!: string;//	中心点纬度
  public is_has_unhandled_events!: boolean;
  public stop_number_info!:StopNumberInfo;


  public static parseScopePath(parking_point_scope: Array<string>): Array<Array<number>> {
    const path: Array<Array<number>> = [];
    if (parking_point_scope) {
      for (const point of parking_point_scope) {
        if (point.indexOf(",") >= 0) {
          const latlonArr = point.split(",");
          if (latlonArr.length > 1) {
            const lon = Number(latlonArr[0]);
            const lat = Number(latlonArr[1]);
            path.push([lon, lat])
          }
        }
      }
    }
    return path;
  }

}
