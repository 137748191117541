export default class BicycleRecordInfo {

  private _platform_qrcode!: string;
  private _body_num!: string;
  //1：已备案,2：未备案
  private _beian_status!: number;
  private _body_num_two!: string;
  private _company_type!: number;
  private _bicycle_type!: number;
  //车架号
  private _frame_number!: string;

  public get frame_number(): string {
    return this._frame_number;
  }
  public set frame_number(value: string) {
    this._frame_number = value;
  }

  get platform_qrcode(): string {
    return this._platform_qrcode;
  }

  set platform_qrcode(value: string) {
    this._platform_qrcode = value;
  }

  get beian_status(): number {
    return this._beian_status;
  }

  set beian_status(value: number) {
    this._beian_status = value;
  }

  get body_num_two(): string {
    return this._body_num_two;
  }

  set body_num_two(value: string) {
    this._body_num_two = value;
  }

  get body_num(): string {
    return this._body_num;
  }

  set body_num(value: string) {
    this._body_num = value;
  }

  get company_type(): number {
    return this._company_type;
  }

  set company_type(value: number) {
    this._company_type = value;
  }

  get bicycle_type(): number {
    return this._bicycle_type;
  }

  set bicycle_type(value: number) {
    this._bicycle_type = value;
  }
}
