import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '@/pages/login/Login.vue'
import Main from '@/pages/main/Main.vue'
import EventList from '@/pages/eventlist/EventList.vue'
import SuperviseEvent from '@/pages/supervise/create/supervise-event.vue'
import AMapDemo from '@/pages/amapdemo/AMapDemo.vue'
import EventProcess from '@/pages/eventprocess/EventProcess.vue'
import authenticator from '@/pages/authenticator'
import BicycleInfo from '@/pages/bicycle/bicycle-info.vue'
import User from '@/pages/user/user.vue'
import ModifyPwd from '@/pages/user/pwd/modify-pwd.vue'
import SuperviseEventDetail from '@/pages/supervise/detail/supervise-event-detail.vue'
import LocationSelector from '@/pages/locationselector/LocationSelector.vue'
import { RouterDomain } from '@/domain/router-domain'
import ScanRecordBicycle from '@/pages/record/scan-record-bicycle.vue'
import AdviceLocationSelector from '@/pages/advice/map/advice-location-selector.vue'
import PublicAdvice from '@/pages/advice/public-advice.vue'
import Statistics from '@/pages/statistics/Statistics.vue'
import EventSubmits from '@/pages/event-submits/EventSubmits.vue'
import RecommendList from '@/pages/recommend-list/RecommendList.vue'
import RecommendDetail from '@/pages/recommend-detail/RecommendDetail.vue'
import AssessmentRanking from '@/pages/assessment-ranking/assessment-ranking.vue'
import CompanyAssessmentRanking from '@/pages/company-assessment-ranking/company-assessment-ranking.vue'
import CompanyCoordinate from '@/pages/company-coordinate/company-coordinate.vue'
import RegionRanking from '@/pages/region-ranking/region-ranking.vue'
import RealTimeMap from '@/pages/real-time-map/real-time-map.vue'
import DataStatistics from '@/pages/data-statistics/data-statistics.vue'
import EarlyWarning from '@/pages/early-warning/EarlyWarning.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/main'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/main',
    name: RouterDomain.MAIN,
    component: Main,
    meta: {
      title: '单车监管'
    },
    beforeEnter: (to, from, next) => {
      authenticator.auth(() => {
        next()
      }, () => {
        next({ name: 'Login', replace: true })
      })
    }
  },
  {
    path: '/event-list',
    name: 'EventList',
    component: EventList,
    meta: {
      title: '事件处理记录'
    }
  },
  {
    path: '/event-submits',
    name: 'EventCommits',
    component: EventSubmits,
    meta: {
      title: '事件提交记录'
    }
  },
  {
    path: '/recommend-list',
    name: 'RecommendList',
    component: RecommendList,
    meta: {
      title: '建议记录'
    }
  },
  {
    path: '/recommend-detail',
    name: 'RecommendDetail',
    component: RecommendDetail,
    meta: {
      title: '建议详情'
    }
  },
  {
    path: '/event-process',
    name: RouterDomain.EVENTPROCESS,
    component: EventProcess,
    meta: {
      title: '事件处理'
    }
  },
  {
    path: '/supervise-event',
    name: RouterDomain.SUPERVISE_EVENT,
    component: SuperviseEvent,
    meta: {
      title: '监管事件'
    }
  },
  {
    path: '/amap-demo',
    name: 'AMapDemo',
    component: AMapDemo,
    meta: {
      title: '高德demo'
    }
  },
  {
    path: '/bicycle-info',
    name: RouterDomain.BICYCLE_INFO,
    component: BicycleInfo,
    meta: {
      title: '获取单车信息'
    }
  },
  {
    path: '/user',
    name: 'User',
    component: User,
    meta: {
      title: '个人中心'
    }
  },
  {
    path: '/modify-pwd',
    name: 'modifyPwd',
    component: ModifyPwd,
    meta: {
      title: '修改密码'
    }
  },
  {
    path: '/supervise-event-detail',
    name: 'SuperviseEventDetail',
    component: SuperviseEventDetail,
    meta: {
      title: '事件详情'
    }
  },
  {
    path: '/location-selector',
    name: 'LocationSelector',
    component: LocationSelector,
    meta: {
      title: '选择位置'
    }
  },
  {
    path: '/advice-location-selector',
    name: 'AdviceLocationSelector',
    component: AdviceLocationSelector,
    meta: {
      title: '选择位置'
    }
  },
  {
    path: '/scan-record-bicycle',
    name: RouterDomain.SCAN_RECORD_BICYCLE,
    component: ScanRecordBicycle,
    meta: {
      title: '扫码验车'
    }
  },
  {
    path: '/public-advice',
    name: RouterDomain.PUBLIC_ADVICE,
    component: PublicAdvice,
    meta: {
      title: '建议'
    }
  },
  {
    path: '/statistics',
    name: RouterDomain.statistics,
    component: Statistics,
    meta: {
      title: '实时信息'
    }
  },
  {
    path: '/assessment-ranking',
    name: RouterDomain.ASSESSMENT_RANKING,
    component: AssessmentRanking,
    meta: {
      title: '考核排名'
    }
  },
  {
    path: '/company-assessment-ranking',
    name: RouterDomain.COMPANY_ASSESSMENT_RANKING,
    component: CompanyAssessmentRanking,
    meta: {
      title: '企业考核排名'
    }
  },
  {
    path: '/region-ranking',
    name: RouterDomain.REGION_RANKING,
    component: RegionRanking,
    meta: {
      title: '区考核排名'
    }
  },
  {
    path: '/company-coordinate',
    name: RouterDomain.COMPANY_COORDINATE,
    component: CompanyCoordinate,
    meta: {
      title: '企业配合度录入'
    }
  },
  {
    path: '/real-time-map',
    name: RouterDomain.REAL_TIME_MAP,
    component: RealTimeMap,
    meta: {
      title: '实时地图'
    }
  },
  {
    path: '/data-statistics',
    name: RouterDomain.DATA_STATISTICS,
    component: DataStatistics,
    meta: {
      title: '数据统计'
    }
  },
  {
    path: '/early-warning',
    name: RouterDomain.EARLY_WARNING,
    component: EarlyWarning,
    meta: {
      title: '预警提醒'
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to: any, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
