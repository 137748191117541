import {Component, Vue} from "vue-property-decorator";
import {Calendar, Cell, DatetimePicker, DropdownItem, DropdownMenu, Icon, List, Picker, Popup} from "vant";
import TimeUtil from "@/util/time-util";
import {StatisticsRequest} from "@/request/statistics-request";
import HttpResponseUtil from "@/http/HttpResponseUtil";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import {ToastUtil} from "@/util/toast-util";
import {NormalDataStatistics} from "@/biz/normal-data-statistics";
import UserInfo from "@/biz/user-info";
import {LocalStorageAgent} from "@/storage/local-storage-agent";
import {BicycleUtil} from "@/util/bicycle-util";
import {DataStatisticsDomain} from "@/domain/data-statistics-domain";


@Component({
  components: {
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Icon.name]: Icon,
    [List.name]: List,
    [Cell.name]: Cell,
    [DatetimePicker.name]: DatetimePicker,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Calendar.name]: Calendar
  }
})
export default class DataStatistics extends Vue {
  public showCalendar = false;
  public showDatePicker = false;
  public showYearPicker = false;
  public showTimeView = false;
  public showEndTimeView = false;
  public currentDate = new Date();
  public minDateTime = new Date(2021, 7, 1);
  public maxDateTime = new Date();
  public menus = [
    {text: '按日', value: 0},
    {text: '按月', value: 1},
    {text: '按年', value: 2},
  ];
  public menuValue = DataStatisticsDomain.SELECT_BY_DAY;
  public timeType = DataStatisticsDomain.SELECT_START_TIME;

  public startTimeStr = '起始时间';
  public endTimeStr = '结束时间';
  public startTime!: number;
  public endTime!: number;

  public hasCityPermission = false;

  public dataStatistics: NormalDataStatistics = new NormalDataStatistics();
  public totalCount: number = 0;
  public loading: boolean = false;
  public finished: boolean = false;

  public years: Array<string> = [];

  private mounted() {
    console.log('mounted');
    const userInfo = LocalStorageAgent.getUserInfo();
    if (userInfo) {
      let userTypes = userInfo.user_types;
      this.hasCityPermission = UserInfo.hasCityPermission(userTypes);
    }
    //初始化当天时间
    this.initTodayTime();
  }

  private initTodayTime() {
    this.startTimeStr = TimeUtil.getSelectDate(this.currentDate, true);
    this.endTimeStr = this.startTimeStr;
    let times = TimeUtil.getTodayStartEndTimestamp();
    this.startTime = times[0] / 1000;
    this.endTime = times[1] / 1000;
  }

  public getCompanyName(company_type: number): string {
    return BicycleUtil.getBicycleCompanyTypeName(company_type);
  }

  public onTimeConfirm(date: Date) {
    console.log(date);
    if (this.menuValue == DataStatisticsDomain.SELECT_BY_MONTH) {
      this.startTimeStr = date.getFullYear() + "/" + (date.getMonth() + 1);
      this.startTime = TimeUtil.getMonthFirstDayTime(date);
      this.endTime = TimeUtil.getNextMonthFirstDayTime(date);
      this.showDatePicker = false;
      this.showTimeView = false;
      this.requestData();
    }
  }

  public onCalendarConfirm(date: Date) {
    console.log(date);
    if (this.menuValue == DataStatisticsDomain.SELECT_BY_DAY) {
      if (this.timeType === DataStatisticsDomain.SELECT_START_TIME) {
        this.startTimeStr = TimeUtil.getSelectDate(date, true);
        this.startTime = TimeUtil.getDayZeroTime(date);
      } else {
        this.endTimeStr = TimeUtil.getSelectDate(date, true);
        this.endTime = TimeUtil.getDayZeroTime(date) + 24 * 60 * 60;
      }
      this.showCalendar = false;
      this.showTimeView = false;
      this.showEndTimeView = false;
      this.requestData();
    }
  }

  public onTimeCancel() {
    this.showTimeView = false;
    this.showCalendar = false;
  }

  public onMenuChange(value: number) {
    this.menuValue = value;
    if (this.menuValue == DataStatisticsDomain.SELECT_BY_DAY) {
      this.maxDateTime = new Date();
      this.setCurrentDate(this.maxDateTime);
      this.minDateTime = new Date(2021, 7, 1);
      this.initTodayTime();
    } else if (this.menuValue == DataStatisticsDomain.SELECT_BY_MONTH) {
      this.maxDateTime = new Date();
      this.minDateTime = new Date(2021, 7, 1);
      this.startTimeStr = TimeUtil.getSelectDate(this.maxDateTime, false);
      this.setCurrentDate(this.maxDateTime);
      this.startTime = TimeUtil.getMonthFirstDayTime(this.maxDateTime);
      this.endTime = TimeUtil.getNextMonthFirstDayTime(this.maxDateTime);
    } else {
      this.maxDateTime = new Date();
      this.minDateTime = new Date(2021, 7, 1);
      this.startTimeStr = this.maxDateTime.getFullYear() + "年";
      this.setCurrentDate(this.maxDateTime);
      this.startTime = TimeUtil.getFirstDayOfYear(new Date());
      this.endTime = TimeUtil.getLastDayOfYear(new Date());
    }
    this.requestData();
  }

  public showCalendarClick(type: number) {
    if (this.menuValue == DataStatisticsDomain.SELECT_BY_DAY) {
      this.showCalendar = true;
      this.timeType = type;
      if (type === DataStatisticsDomain.SELECT_START_TIME) {
        this.showTimeView = true;
      } else {
        this.showEndTimeView = true;
      }
    } else if (this.menuValue == DataStatisticsDomain.SELECT_BY_MONTH) {
      this.showDatePicker = true;
      this.showTimeView = true;
    } else {
      this.years = TimeUtil.getYearsPicker();
      this.showYearPicker = true;
      this.showTimeView = true;
    }
  }

  public setCurrentDate(currentDate: Date) {
    this.currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  }

  private requestData() {
    if (this.startTime === 0 || this.endTime === 0) {
      return;
    }
    if (this.endTime < this.startTime) {
      ToastUtil.showMessage('请选择正确结束时间');
      return;
    }
    let section = this.startTime + ',' + this.endTime;
    StatisticsRequest.getDataStatistics(section).then((response) => {
      this.dataStatistics = HttpResponseUtil.parseJSONStr(NormalDataStatistics, response.data);
      this.finished = true;
    }).catch((error) => {
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      if (error.response?.status === 422) {
        resError = '数据获取失败';
      }
      if (error.response?.status !== 404) {
        ToastUtil.showMessage(resError);
      }
    });
  }

  public onLoad() {
    this.requestData();
  }

  public onYearConfirm(value: number, index: number) {
    if (this.menuValue == DataStatisticsDomain.SELECT_BY_YEAR) {
      this.startTimeStr = value + "年";
      this.startTime = TimeUtil.getFirstDayOfYearByPicker(Number(value));
      this.endTime = TimeUtil.getLastDayOfYearByPicker(Number(value));
    }
    this.showYearPicker = false;
    this.showTimeView = false;
    this.requestData();
  }

  public onYearCancel() {
    this.showYearPicker = false;
    this.showTimeView = false;
  }
}
