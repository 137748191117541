import {Component, Vue} from "vue-property-decorator";
import {Button, Field, Form} from "vant";
import UserInfo from "@/biz/user-info";
import {LocalStorageAgent} from "@/storage/local-storage-agent";
import {ComponentDomain} from "@/domain/component-domain";

@Component({
  name: ComponentDomain.MAIN,
  components: {[Button.name]: Button, [Form.name]: Form, [Field.name]: Field}
})

export default class Main extends Vue {

  private hasNormalPermission = false;

  constructor() {
    super()
  }

  private created(): void {
    console.log('created');
  }

  private mounted() {
    // var VConsole = require('vconsole');
    // var vConsole = new VConsole();

    const userInfo = LocalStorageAgent.getUserInfo();
    let userTypes;
    if (userInfo) {
      userTypes = userInfo.user_types;
      this.hasNormalPermission = UserInfo.hasNormalUserPermission(userTypes);
    }
  }

  public onInformationClick() {
    this.$router.push({path: '/statistics'})
  }

  public onSuperviseClick() {
    this.$router.push({path: '/supervise-event'})
  }

  public onSuggestionClick() {
    this.$router.push({path: '/public-advice'})
  }

  public onMeClick() {
    this.$router.push({path: '/user'})
  }

  public onScanBicycleClick() {
    this.$router.push({path: '/scan-record-bicycle'})
  }

  public onAssessmentRankingClick() {
    this.$router.push({path: '/assessment-ranking'})
  }

  public onRealTimeMapClick() {
    this.$router.push({path: '/real-time-map'})
  }

  public onEarlyWaringClick() {
    this.$router.push({path: '/early-warning'})
  }
}
