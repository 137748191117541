import axiosagent from "@/http/axios_agent";
import {AxiosPromise} from "axios";
import HttpRequestUtil from "@/http/HttpRequestUtil";

export class RankingRequest {
  /**
   * 获取企业配合度详情
   */
  public static getCompanyCooperateDegreeDetail(): AxiosPromise {
    return axiosagent.shared_bicycle_service.get('api/assessment/company_cooperate_degree_detail');
  }

  /**
   * 获取企业配合度设置
   */
  public static getCompanyCooperateDegreeSetting(): AxiosPromise {
    return axiosagent.shared_bicycle_service.get('api/assessment/company_cooperate_degree_setting');
  }

  /**
   * 获取考核时间设置
   */
  public static getCompanyCooperateTimeSetting(): AxiosPromise {
    return axiosagent.shared_bicycle_service.get('api/assessment/assess_time_setting');
  }

  /**
   * 创建企业配合度
   * @param {number} qingju_score
   * @param {number} meituan_score
   * @param {number} haluo_score
   * @returns {AxiosPromise}
   */
  public static createCompanyCooperateDegree(qingju_score: string, meituan_score: string, haluo_score: string): AxiosPromise {
    return axiosagent.shared_bicycle_service.post('api/assessment/company_cooperate_degrees', {
      qingju_score: qingju_score,
      meituan_score: meituan_score,
      haluo_score: haluo_score
    });
  }


  /**
   * 市企业考核天统计
   * @param assessTime
   */
  public static getCompanyStatisticsCityByDay(assessTime?: number): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/company/city_by_day?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   * 市企业考核周统计
   * @param assessTime
   */
  public static getCompanyStatisticsCityByWeek(assessTime?: number): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/company/city_by_week?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   * 市企业考核月统计
   * @param assessTime
   */
  public static getCompanyStatisticsCityByMonth(assessTime?: number): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/company/city_by_month?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   * 区企业考核天统计
   * @param assessTime
   * @param regionCode
   */
  public static getCompanyStatisticsRegionByDay(assessTime?: number, regionCode?: string): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    if (regionCode) {
      paramMap.set('region_code', regionCode);
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/company/region_by_day?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   *区企业考核周统计
   * @param assessTime
   * @param regionCode
   */
  public static getCompanyStatisticsRegionByWeek(assessTime?: number, regionCode?: string): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    if (regionCode) {
      paramMap.set('region_code', regionCode);
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/company/region_by_week?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   *区企业考核月统计
   * @param assessTime
   * @param regionCode
   */
  public static getCompanyStatisticsRegionByMonth(assessTime?: number, regionCode?: string): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    if (regionCode) {
      paramMap.set('region_code', regionCode);
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/company/region_by_month?' + HttpRequestUtil.encodeParameters(paramMap));
  }


  /**
   *街道企业考核天统计
   * @param assessTime
   * @param regionCode
   * @param streetCode
   * @param pageSize
   * @param nextUrl
   */
  public static getCompanyStatisticsStreetByDay(assessTime?: number, regionCode?: string,
                                                streetCode?: string | null, pageSize?: number, nextUrl?: string): AxiosPromise {

    if (nextUrl) {
      return axiosagent.shared_bicycle_service.get(nextUrl);
    }
    let paramMap = new Map<string, any>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    if (regionCode) {
      paramMap.set('region_code', regionCode);
    }
    if (streetCode) {
      paramMap.set('street_code', streetCode);
    }
    if (pageSize) {
      paramMap.set('page_size', pageSize);
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/company/street_by_day?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   *街道企业考核周统计
   * @param assessTime
   * @param regionCode
   * @param streetCode
   * @param pageSize
   * @param nextUrl
   */
  public static getCompanyStatisticsStreetByWeek(assessTime?: number, regionCode?: string,
                                                 streetCode?: string | null, pageSize?: number, nextUrl?: string): AxiosPromise {

    if (nextUrl) {
      return axiosagent.shared_bicycle_service.get(nextUrl);
    }
    let paramMap = new Map<string, any>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    if (regionCode) {
      paramMap.set('region_code', regionCode);
    }
    if (streetCode) {
      paramMap.set('street_code', streetCode);
    }
    if (pageSize) {
      paramMap.set('page_size', pageSize);
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/company/street_by_week?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   *街道企业考核月统计
   * @param assessTime
   * @param regionCode
   * @param streetCode
   * @param pageSize
   * @param nextUrl
   */
  public static getCompanyStatisticsStreetByMonth(assessTime?: number, regionCode?: string,
                                                  streetCode?: string | null, pageSize?: number, nextUrl?: string): AxiosPromise {

    if (nextUrl) {
      return axiosagent.shared_bicycle_service.get(nextUrl);
    }
    let paramMap = new Map<string, any>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    if (regionCode) {
      paramMap.set('region_code', regionCode);
    }
    if (streetCode) {
      paramMap.set('street_code', streetCode);
    }
    if (pageSize) {
      paramMap.set('page_size', pageSize);
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/company/street_by_month?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   *区行政考核天统计
   * @param assessTime
   * @param regionCode
   */
  public static getRegionStatisticsByDay(assessTime?: number, regionCode?: string | null): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    if (regionCode) {
      paramMap.set('region_code', regionCode);
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/administration/region_by_day?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   *区行政考核周统计
   * @param assessTime
   * @param regionCode
   */
  public static getRegionStatisticsByWeek(assessTime?: number, regionCode?: string | null): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    if (regionCode) {
      paramMap.set('region_code', regionCode);
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/administration/region_by_week?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   *区行政考核月统计
   * @param assessTime
   * @param regionCode
   */
  public static getRegionStatisticsByMonth(assessTime?: number, regionCode?: string | null): AxiosPromise {
    let paramMap = new Map<string, string>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    if (regionCode) {
      paramMap.set('region_code', regionCode);
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/administration/region_by_month?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   *街道行政考核天统计
   * @param assessTime
   * @param regionCode
   * @param streetCode
   * @param pageSize
   * @param nextUrl
   */
  public static getStreetStatisticsByDay(assessTime?: number, regionCode?: string,
                                         streetCode?: string | null, pageSize?: number, nextUrl?: string): AxiosPromise {

    if (nextUrl) {
      return axiosagent.shared_bicycle_service.get(nextUrl);
    }
    let paramMap = new Map<string, any>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    if (regionCode) {
      paramMap.set('region_code', regionCode);
    }
    if (streetCode) {
      paramMap.set('street_code', streetCode);
    }
    if (pageSize) {
      paramMap.set('page_size', pageSize);
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/administration/street_by_day?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   *街道行政考核天统计
   * @param assessTime
   * @param regionCode
   * @param streetCode
   * @param pageSize
   * @param nextUrl
   */
  public static getStreetStatisticsByWeek(assessTime?: number, regionCode?: string,
                                          streetCode?: string | null, pageSize?: number, nextUrl?: string): AxiosPromise {

    if (nextUrl) {
      return axiosagent.shared_bicycle_service.get(nextUrl);
    }
    let paramMap = new Map<string, any>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    if (regionCode) {
      paramMap.set('region_code', regionCode);
    }
    if (streetCode) {
      paramMap.set('street_code', streetCode);
    }
    if (pageSize) {
      paramMap.set('page_size', pageSize);
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/administration/street_by_week?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   *街道行政考核天统计
   * @param assessTime
   * @param regionCode
   * @param streetCode
   * @param pageSize
   * @param nextUrl
   */
  public static getStreetStatisticsByMonth(assessTime?: number, regionCode?: string,
                                           streetCode?: string | null, pageSize?: number, nextUrl?: string): AxiosPromise {

    if (nextUrl) {
      return axiosagent.shared_bicycle_service.get(nextUrl);
    }
    let paramMap = new Map<string, any>();
    if (assessTime) {
      paramMap.set('assess_time', String(assessTime));
    }
    if (regionCode) {
      paramMap.set('region_code', regionCode);
    }
    if (streetCode) {
      paramMap.set('street_code', streetCode);
    }
    if (pageSize) {
      paramMap.set('page_size', pageSize);
    }
    return axiosagent.shared_bicycle_service.get('assessment_statistics/administration/street_by_month?' + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   * 获取企业配合度设置
   */
  public static getRegionCooperateDegreeSetting(): AxiosPromise {
    return axiosagent.shared_bicycle_service.get('assessment_settings/company/region/match_degree');
  }

  /**
   * 获取企业配合度设置
   */
  public static getStreetCooperateDegreeSetting(): AxiosPromise {
    return axiosagent.shared_bicycle_service.get('assessment_settings/company/street/match_degree');
  }

}
