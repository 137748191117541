export default class BicycleTypeDomain {
  /*
    * 青桔
    * */
  public static readonly COMPANY_DIDI_TYPE = 1;
  /*
   * 哈啰
   * */
  public static readonly COMPANY_HALLO_TYPE = 2;
  /*
   * 美团
   * */
  public static readonly COMPANY_MoBAI_TYPE = 3;
  /*
   * 单车
   * */
  public static readonly NORMAL_BICYCLE = 1;
  /*
   * 助力车
   * */
  public static readonly ELECTRIC_BICYCLE = 2;


  /**
   * 已备案
   */
  public static readonly BLUE_BICYCLE_RECORDED = 1;
  /**
   * 未备案
   */
  public static readonly BLUE_BICYCLE_UNRECORD = 2;


  /**
   * 调度车
   */
  public static readonly DISPATCH_TYPE_BICYCLE = 1;
  /**
   * 调度人
   */
  public static readonly DISPATCH_TYPE_PERSON = 2;
}
