import { render, staticRenderFns } from "./scan-record-bicycle.vue?vue&type=template&id=3b704455&scoped=true&"
import script from "./scan-record-bicycle.ts?vue&type=script&lang=ts&"
export * from "./scan-record-bicycle.ts?vue&type=script&lang=ts&"
import style0 from "./scan-record-bicycle.less?vue&type=style&index=0&id=3b704455&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b704455",
  null
  
)

export default component.exports