import {Component, Vue} from "vue-property-decorator";
import {ToastUtil} from "@/util/toast-util";
import {AmapUtil} from "@/util/amap-util";
import {WxAgent} from "@/wx/wx-agent";
import {Button} from "vant";

@Component({
  components: {[Button.name]: Button}
})
export default class AMapDemo extends Vue {

  private map: any;

  constructor() {
    super()
  }

  private created(): void {
    ToastUtil.showLoading('加载中');
    //异步加载地图
    AmapUtil.loadAMapScript(() => {
      this.map = new AMap.Map('container');
      this.map.on('complete', () => {
        // 地图图块加载完成后触发
        ToastUtil.hide();
        console.log('on map load complete');
      });
    });
  }

  private mounted() {

  }

  public onLocateClick(): void {
    if (this.map) {
      // 高德获取定位
      AmapUtil.getLocation(this.map, (status: any, result: any) => {
        if (status == 'complete') {
          console.log(result);
          console.log(result.position.lat);
          console.log(result.position.lng);
          ToastUtil.showMessage('高德定位成功 ' + JSON.stringify(result))
          const marker = AmapUtil.getMarker(result.position.lat, result.position.lng, null);
          //添加marker
          this.map.add(marker);
          console.log('amap:' + 'latitude:' + result.position.lat + ",longitude:" + result.position.lng);
          //添加窗体
          var infoWindow = new AMap.InfoWindow({
            anchor: 'top-left',
            content: '我是高德定位',
          });
          infoWindow.open(this.map, [result.position.lng, result.position.lat])
        } else {
          console.log(result);
          ToastUtil.showMessage('高德定位失败 ' + JSON.stringify(result))
        }
      });

      // 微信获取定位
      WxAgent.getLocation((res: any) => {
        const latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
        const longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
        console.log('wx:' + 'latitude:' + latitude + ",longitude:" + longitude);
        const marker = AmapUtil.getMarker(latitude, longitude, null);
        //添加marker
        this.map.add(marker);

        //坐标转转
        AmapUtil.convertFrom([longitude, latitude], (status: any, result: any) => {
          console.log(status);
          console.log('转换后的坐标')
          console.log(JSON.stringify(result));
        });
      }, (error: any) => {
        console.log(JSON.stringify(error));
      }, () => {
        console.log('on complete');
      });
    }

  }

  public onDrawPolygonClick(): void {
    if (this.map) {
      const path = [
        [116.403322, 39.920255],
        [116.410703, 39.897555],
        [116.402292, 39.892353],
        [116.389846, 39.891365]
      ]

      const polygon = AmapUtil.getPolygon(path);
      //添加多边形
      this.map.add(polygon);

      // 缩放地图到合适的视野级别
      this.map.setFitView([polygon])
    }
  }


}
