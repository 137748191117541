import {Component, Vue} from "vue-property-decorator";
import {Col, Grid, GridItem, ImagePreview, Row} from "vant";
import TimeUtil from "@/util/time-util";
import RecommendEvent from "@/biz/recommend-event";
import {AxiosError, AxiosResponse} from "axios";
import HttpResponseUtil from "@/http/HttpResponseUtil";
import HttpResErrorPaser from "@/http/error/HttpResErrorPaser";
import {ToastUtil} from "@/util/toast-util";
import {AdviceRequest} from "@/request/advice-request";
import ImageUtil from "@/util/image-util";

@Component({
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Col.name]: Col,
    [Row.name]: Row,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  filters: {
    dataFormat(value: number): string {
      return TimeUtil.getDateYYYYMMDDHHMMSS(value).replace(' ', '\r\n');
    },
    eventCategory(eventCategory: number): string {
      switch (eventCategory) {
        case 1:
          return '停放点不合理';
        case 2:
          return '建议增加停放点';
        default:
          return '';
      }
    },
    eventStatus(dealStatus: number): string {
      switch (dealStatus) {
        case 1:
          return '已提交';
        case 2:
          return '已采纳';
        case 3:
          return '已驳回';
        default:
          return '';
      }
    },
    bicycleType(bicycleType: Array<number>): string {
      const bicycleTypeText = bicycleType.map((item) => {
          switch (item) {
            case 1:
              return '单车';
            case 2:
              return '助力车';
            default:
              return '';
          }
        }
      );
      if (bicycleTypeText) {
        return bicycleTypeText.join('、')
      }
      return '';
    }
  }
})
export default class RecommendDetail extends Vue {

  public recommendEventId = '';
  public eventDetail: RecommendEvent | null = null;

  private mounted() {
    this.recommendEventId = this.$route.query.recommendEventId as any;
    this.requestEventDetail();
  }

  public onPhotoItemClick(photos: string[], index: number) {
    let photoArray: string[] = [];
    photos.map(item => {
      photoArray.push(ImageUtil.getPhotoPath(item));
    });
    ImagePreview({
      images: photoArray,
      startPosition: index,
      closeable: true
    });
  }

  private requestEventDetail() {
    AdviceRequest.getRecommendEventDetail(this.recommendEventId)
      .then((response: AxiosResponse) => {
        this.eventDetail = HttpResponseUtil.parseJSONStr(RecommendEvent, response.data);
      }).catch((error: AxiosError) => {
      let resError = HttpResErrorPaser.parseResError(error.response?.status, error.response?.data.errors, null);
      if (error.response?.status === 422) {
        resError = '数据获取失败';
      }
      ToastUtil.showMessage(resError);
    });
  }


  public getPhotoPath(path: string): string {
    return ImageUtil.getPhotoPath(path)
  }

}
